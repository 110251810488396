import { QualificationBadge } from 'components/badges';
import { ProgressWidget } from 'components/progress/progress-loop/ProgressWidget';
import { Disclaimer, LabelledText } from 'components/text';
import { QUALIFICATIONS, QualificationLevel } from 'contants';
import { useScreenEvents } from 'contexts';
import { replaceStringWithComponent } from 'libs/components';
import { FeatureIconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { Fragment, useMemo } from 'react';
import { IUserQualificationData } from 'types';

import { WidgetQualificationDetails } from './WidgetQualificationDetails';
import {
  CurrentQualificationHeader,
  NextQualificationHeader
} from '../firstline/headers/QualificationHeaders';
import { Widget } from '../Widget';

interface IWidgetQualificationProps extends IUserQualificationData {
  readonly userId: string;
  readonly currentQualificationLevel: QualificationLevel | undefined;
  readonly canReachCheckAssurance: boolean;
}

export function WidgetQualification(props: IWidgetQualificationProps) {
  const {
    userId,
    nextQualificationLevel,
    nextQualificationProgress,
    qualificationHistory,
    currentQualificationLevel
  } = props;

  const { lg } = useScreenEvents();
  const { t } = useTranslation();

  const qualificationProgressChart = useMemo(() => {
    return (
      <div className="flex flex-col items-center">
        <ProgressWidget percentage={nextQualificationProgress.percent} />
        {!_.isUndefined(nextQualificationLevel) && (
          <h2
            className={`font-bold ${
              nextQualificationProgress.percent === 100
                ? 'text-success'
                : 'text-primary'
            }`}
          >
            {replaceStringWithComponent(t('to {{level}}'), '{{level}}', () => (
              <QualificationBadge qualification={nextQualificationLevel} />
            )).map((elem, index) => (
              <Fragment key={`to_level__${index}`}>{elem}</Fragment>
            ))}
          </h2>
        )}
      </div>
    );
  }, [nextQualificationLevel, nextQualificationProgress.percent, t]);

  return (
    <Widget
      traceId="Qualification"
      title={t('Qualification')}
      iconName={FeatureIconName.Qualification}
      detailedViewButtonLabel={t('Details')}
      content={
        <div className="flex flex-row items-center justify-center sm:justify-evenly">
          {qualificationProgressChart}

          <div className="space-y-xs w-1/2 hidden sm:block">
            {!_.isUndefined(currentQualificationLevel) && (
              <LabelledText
                inline={!lg}
                label={<CurrentQualificationHeader />}
                value={
                  <div className="flex items-center">
                    <QualificationBadge
                      qualification={currentQualificationLevel}
                      currentMonth
                    />
                    <span className="ml-xxs hidden lg:inline-block">
                      {QUALIFICATIONS[currentQualificationLevel].name}
                    </span>
                  </div>
                }
              />
            )}
            {_.isUndefined(currentQualificationLevel) &&
              props.canReachCheckAssurance && (
                <Disclaimer
                  message={t('Check Assurance is needed to qualify.')}
                />
              )}
            {!_.isUndefined(nextQualificationLevel) && (
              <LabelledText
                inline={!lg}
                label={<NextQualificationHeader />}
                value={
                  <div className="flex items-center">
                    <QualificationBadge
                      qualification={nextQualificationLevel}
                    />
                    <span className="ml-xxs hidden lg:inline-block">
                      {QUALIFICATIONS[nextQualificationLevel].name}
                    </span>
                  </div>
                }
              />
            )}
          </div>
        </div>
      }
      detailedView={
        !_.isUndefined(nextQualificationLevel) && (
          <WidgetQualificationDetails
            userId={userId}
            nextQualificationLevel={nextQualificationLevel}
            nextQualificationProgress={nextQualificationProgress}
            qualificationHistory={qualificationHistory}
            currentQualificationLevel={currentQualificationLevel}
            canReachCheckAssurance={props.canReachCheckAssurance}
          />
        )
      }
    />
  );
}

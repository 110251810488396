// eslint-disable-next-line no-restricted-imports
import { DateTime } from 'luxon';

export class Month {
  private _date: DateTime;

  // Always use local() time to ensure the date is not affected by timezones
  private static TODAY = DateTime.local();

  private constructor(value: string) {
    // Remove time information if it exists
    const sanitizedValue = value.split('T')[0];

    const parsedDate = DateTime.fromISO(sanitizedValue);

    // The month is represented by the 1st of each month
    this._date = parsedDate.plus({ day: 1 - parsedDate.day });

    // Mark the date as "local" to ensure it will be presented equaly, anywhere in the world.
    this._date = this._date.toLocal();
  }

  static create(value: string): Month | undefined {
    const date = new Month(value);

    if (!date._date.isValid) {
      return undefined;
    }

    return date;
  }

  static createOrThrow(value: string): Month {
    const date = Month.create(value);

    if (date === void 0) {
      throw new Error('Invalid Month');
    }

    return date;
  }

  static today(): Month {
    return Month.createOrThrow(Month.TODAY.toFormat('yyyy-MM-dd'));
  }

  public isTodaysMonth(): boolean {
    return (
      Month.TODAY.year === this._date.year &&
      Month.TODAY.month === this._date.month
    );
  }

  public nextMonth(): Month {
    const newMonth = Month.today();
    newMonth._date = this._date.plus({ month: 1 });
    return newMonth;
  }

  public previousMonth(): Month {
    const newMonth = Month.today();
    newMonth._date = this._date.plus({ month: -1 });
    return newMonth;
  }

  public toLocaleString(locale: string): string {
    return this._date
      .setLocale(locale)
      .toLocaleString({ year: '2-digit', month: 'short' });
  }

  public toString(): string {
    return this._date.toFormat('yyyy-MM-dd');
  }

  public toJSON(): string {
    return this.toString();
  }

  public getTime(): number {
    return this._date.toMillis();
  }
}

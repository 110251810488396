import { Month } from 'libs/time';
import _ from 'lodash';
import { IUserBusinessData } from 'types';

import { parseAPIUserBusinessData } from './BusinessDataServiceHelper';
import { execGet } from '../RequestUtils';
import { IUserBusinessDataApi } from './types/IUserBusinessDataApi';

export async function fetchBusinessData(
  userId: string,
  monthsOfHistoricalData: number,
  period?: Month
): Promise<IUserBusinessData> {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append('include', 'BusinessData,AutoshipDetails');
  urlSearchParams.append('historicalMonths', monthsOfHistoricalData.toString());
  if (!_.isUndefined(period)) {
    urlSearchParams.append('period', period.toString());
  }

  const response = await execGet<{
    readonly businessData: IUserBusinessDataApi;
    readonly qualification?: string;
    readonly calculationDate?: string;
  }>(
    `${
      process.env.REACT_APP_OFFICE_API
    }/users/${userId}?${urlSearchParams.toString()}`
  );
  const userBusinessData = parseAPIUserBusinessData(
    response.businessData,
    response.calculationDate,
    response.qualification
  );

  // On dev environments, the data returned could be from a different
  // user for testing reasons. To ensure everything is as expected when
  // it comes to this frontend application, we need to at least fix the userId
  const sanitizedUserBusinessData = {
    ...userBusinessData,
    userId:
      userBusinessData.userId !== userId ? userId : userBusinessData.userId
  };

  return sanitizedUserBusinessData;
}

import { Month } from 'libs/time';
import _ from 'lodash';

import { DashboardContent } from './DashboardContent';
import { DashboardContentSkeleton } from './DashboardContentSkeleton';
import { DashboardError } from './DashboardError';
import { useBusinessData, useFirstlineData } from './hooks';

interface IDashboardLoaderProps {
  readonly userId: string;
  readonly historicalMonths: number;
  readonly dashboardMonth: Month;
}

export function DashboardLoader(props: IDashboardLoaderProps) {
  const {
    data: businessData,
    error: businessDataError,
    isLoading: businessDataLoading
  } = useBusinessData({
    userId: props.userId,
    historicalMonths: props.historicalMonths,
    month: props.dashboardMonth
  });
  const { firstlineData, firstlineDataError, firstlineDataLoading } =
    useFirstlineData({
      userId: props.userId,
      historicalMonths: props.historicalMonths,
      month: props.dashboardMonth
    });

  if (businessDataLoading || firstlineDataLoading) {
    return (
      <div className="animate-pulse">
        <DashboardContentSkeleton />
      </div>
    );
  }

  if (businessDataError) {
    return <DashboardError error={businessDataError} userId={props.userId} />;
  }

  if (firstlineDataError) {
    return <DashboardError error={firstlineDataError} userId={props.userId} />;
  }

  if (_.isUndefined(businessData) || _.isUndefined(firstlineData)) {
    return null;
  }

  return (
    <DashboardContent
      {...businessData}
      firstlineData={firstlineData}
      period={props.dashboardMonth}
    />
  );
}

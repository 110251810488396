import { FormattedUnitValue } from 'components/text';
import {
  InvoiceCostItem,
  InvoiceCostTotal,
  InvoiceCosts,
  InvoiceSectionDivider
} from 'features/invoices';
import { replaceStringWithComponent } from 'libs/components';
import { useTranslation } from 'libs/translations';
import { Fragment } from 'react/jsx-runtime';
import { IBasket } from 'types';
import { DiscountType } from 'utils';

interface IBasketCostsProps extends IBasket {
  readonly showPoints: boolean;
}

export function BasketCosts({ showPoints, ...basketProps }: IBasketCostsProps) {
  const { t } = useTranslation();

  return (
    <InvoiceCosts>
      <InvoiceCostItem
        label={t('Subtotal')}
        value={
          <FormattedUnitValue
            unit={basketProps.basket.totals.currencyCode}
            value={basketProps.basket.totals.itemsDisplayTotal}
          />
        }
      />
      <InvoiceCostItem
        label={t('Tax')}
        value={
          <FormattedUnitValue
            unit={basketProps.basket.totals.currencyCode}
            value={basketProps.basket.totals.totalTaxAmount}
          />
        }
      />
      {basketProps.basket.delivery?.selected.cost?.displayPrice && (
        <InvoiceCostItem
          label={t('Shipping & Handling')}
          value={
            <FormattedUnitValue
              unit={basketProps.basket.delivery.selected.cost.currencyCode}
              value={basketProps.basket.delivery.selected.cost.displayPrice}
            />
          }
        />
      )}
      {basketProps.basket.additionalCharges &&
        basketProps.basket.additionalCharges.length > 0 && (
          <InvoiceCostItem
            label={t('Additional charges')}
            value={
              <FormattedUnitValue
                unit={basketProps.basket.totals.currencyCode}
                value={basketProps.basket.additionalCharges.reduce(
                  (acc, elem) => acc + (elem.cost?.displayPrice ?? 0),
                  0
                )}
              />
            }
          />
        )}
      <InvoiceSectionDivider thick />
      <InvoiceCostTotal
        label={t('Total')}
        value={
          <>
            {showPoints && (
              <span className="block">
                <FormattedUnitValue
                  unit={'points'}
                  value={basketProps.basket.totals.points}
                />
              </span>
            )}
            {basketProps.basket.totals.discountAmount !== 0 && (
              <span className="text-silver text-sm line-through mr-xxs block font-bold">
                <FormattedUnitValue
                  unit={basketProps.basket.totals.currencyCode}
                  value={basketProps.basket.totals.totalGrossExcludingDiscount}
                />
              </span>
            )}
            <span className="block font-bold text-xl">
              <FormattedUnitValue
                unit={basketProps.basket.totals.currencyCode}
                value={basketProps.basket.totals.totalGross}
              />
            </span>
            <span className="block pmi-caption text-silver">{`+ ${t(
              'Shipping if applicable'
            )}`}</span>

            {basketProps.basket.discount > 0 && (
              <span className="block pmi-caption text-silver">
                {replaceStringWithComponent(
                  t(
                    'Includes {{discountFormattedValue}} Team Partner discount'
                  ),
                  '{{discountFormattedValue}}',
                  () => (
                    <FormattedUnitValue
                      unit={DiscountType.Percentage}
                      value={basketProps.basket.discount}
                    />
                  )
                ).map((elem, index) => (
                  <Fragment key={index}>{elem}</Fragment>
                ))}
              </span>
            )}

            {basketProps.basket.affiliateDiscount > 0 && (
              <span className="block pmi-caption text-silver">
                {replaceStringWithComponent(
                  t('Includes {{discountFormattedValue}} customer discount'),
                  '{{discountFormattedValue}}',
                  () => (
                    <FormattedUnitValue
                      unit={DiscountType.Percentage}
                      value={basketProps.basket.affiliateDiscount}
                    />
                  )
                ).map((elem, index) => (
                  <Fragment key={index}>{elem}</Fragment>
                ))}
              </span>
            )}
          </>
        }
      />
    </InvoiceCosts>
  );
}

import { Timestamp } from 'libs/time';
import {
  IOrder,
  OrderChannel,
  OrderDeliveryType,
  OrderStatus
} from 'types/orders';
import { IOrderDeliveryPackage } from 'types/orders/IOrderDelivery';

import { mapOrderAddress } from './OrderAddressMapper';
import { mapOrderItem } from './OrderItemMapper';
import { mapOrderPayment } from './OrderPaymentMapper';
import { mapOrderSource } from './OrderSourceMapper';
import { TRACKING_SERVICES } from '../helpers/TrackingServices';
import { IOrderApi, IOrderDeliveryApi } from '../types';

export function mapAPIOrder(apiOrder: IOrderApi): IOrder {
  return {
    ...apiOrder,
    createdOn: Timestamp.createOrThrow(apiOrder.createdOn),
    items: apiOrder.items.map(mapOrderItem),
    status: apiOrder.status.toLowerCase() as OrderStatus,

    payment: mapOrderPayment(apiOrder.payment),
    delivery: {
      selected: {
        ...apiOrder.delivery.selected,
        type: apiOrder.delivery.selected.type as OrderDeliveryType
      },
      address: mapOrderAddress(apiOrder.delivery.address),
      packages: parsePackages(apiOrder.delivery),
      pickupNumber: apiOrder.delivery.pickupNumber
    },
    billing: apiOrder.billing
      ? {
          ...apiOrder.billing,
          address: mapOrderAddress(apiOrder.billing.address)
        }
      : undefined,
    source: mapOrderSource(
      apiOrder.channel.toLowerCase() as OrderChannel,
      apiOrder.subscriptionInfo
    )
  };
}

function parsePackages(
  delivery: IOrderDeliveryApi
): ReadonlyArray<IOrderDeliveryPackage> | undefined {
  if (!delivery.packages || delivery.packages.length === 0) {
    return undefined;
  }

  const { provider } = delivery.selected;
  const urlGenerator = provider
    ? TRACKING_SERVICES[provider]?.computeTrackingUrl
    : undefined;

  return delivery.packages.map(p => {
    return { id: p.id, url: urlGenerator ? urlGenerator(p.id) : undefined };
  });
}

import { QualificationLevel, QUALIFICATIONS } from 'contants';

export function parseQualificationLevel(
  value: number | string
): QualificationLevel | undefined {
  if (typeof value === 'number' || !Number.isNaN(Number(value))) {
    return Object.values(QualificationLevel).find(level => {
      return QUALIFICATIONS[level].numericValue === Number(value);
    });
  }

  if (typeof value === 'string') {
    return Object.values(QualificationLevel).find(
      level =>
        value.toUpperCase().replaceAll(' ', '') ===
        level.toUpperCase().replaceAll(' ', '')
    );
  }

  return undefined;
}

export function isTeampartnerLevel(quali: QualificationLevel) {
  return [
    QualificationLevel.Teampartner,
    QualificationLevel.Teampartner1,
    QualificationLevel.Teampartner2
  ].includes(quali);
}

export function isManagerLevel(quali: QualificationLevel) {
  return [
    QualificationLevel.MinusManager,
    QualificationLevel.Manager,
    QualificationLevel.SalesManager,
    QualificationLevel.MarketingManager,
    QualificationLevel.InternationalMarketingManager
  ].includes(quali);
}

export function isPresidentLevel(quali: QualificationLevel) {
  return [
    QualificationLevel.VicePresident,
    QualificationLevel.ExecutiveVicePresident,
    QualificationLevel.PresidentsTeam,
    QualificationLevel.SilverPresidentsTeam,
    QualificationLevel.GoldPresidentsTeam,
    QualificationLevel.PlatinumPresidentsTeam
  ].includes(quali);
}

export function isChampionLevel(quali: QualificationLevel) {
  return [
    QualificationLevel.ChampionsLeague,
    QualificationLevel.SilverChampionsLeague,
    QualificationLevel.GoldChampionsLeague,
    QualificationLevel.PlatinumChampionsLeague,
    QualificationLevel.Champion
  ].includes(quali);
}

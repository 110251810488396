import { QualificationBadge } from 'components/badges';
import { QualificationLevel } from 'contants';
import { useGlobalConfigsContext } from 'contexts';
import { PII, usePII } from 'features/pii';
import { useTranslation } from 'libs/translations';
import CountUp from 'react-countup';
import { TBusinessMetricProgressUnit, isValidISOCurrencyCode } from 'types';
import {
  formatNumberLocalized,
  formatPercentLocalized,
  formatCustomUnitLocalized,
  formatCurrencyLocalized
} from 'utils';

interface IFormattedUnitValueProps {
  readonly value: number;
  readonly unit: TBusinessMetricProgressUnit;
  readonly animate?: boolean;
  readonly signDisplay?: boolean;
}

export function FormattedUnitValue(props: IFormattedUnitValueProps) {
  const {
    app: {
      office: { enableAnimations }
    }
  } = useGlobalConfigsContext();
  const { activeLanguage } = useTranslation();
  const { isPrivacyGuardEnabled } = usePII();

  const animate = enableAnimations && props.animate;

  if (props.unit === 'percent' || props.unit === 'percentage') {
    if (!animate || isPrivacyGuardEnabled) {
      return (
        <span className="whitespace-nowrap">
          <PII
            type="number"
            value={formatPercentLocalized(
              activeLanguage,
              props.value,
              props.signDisplay
            )}
          />
        </span>
      );
    }

    return (
      <span className="whitespace-nowrap">
        <CountUpNumber
          value={props.value}
          formattingFn={n =>
            formatPercentLocalized(activeLanguage, n, props.signDisplay)
          }
        />
      </span>
    );
  }

  if (props.unit === 'count') {
    if (!animate || isPrivacyGuardEnabled) {
      return (
        <span className="whitespace-nowrap">
          <PII
            type="number"
            value={formatNumberLocalized(
              activeLanguage,
              props.value,
              props.signDisplay
            )}
          />
        </span>
      );
    }

    return (
      <span className="whitespace-nowrap">
        <CountUpNumber
          value={props.value}
          formattingFn={n =>
            formatNumberLocalized(activeLanguage, n, props.signDisplay)
          }
        />
      </span>
    );
  }

  if (props.unit === 'points') {
    if (!animate || isPrivacyGuardEnabled) {
      return (
        <span className="whitespace-nowrap">
          <PII
            type="points"
            value={formatNumberLocalized(
              activeLanguage,
              props.value,
              props.signDisplay
            )}
          />
          {'\u00A0'}
          <span className="text-silver font-bold">Ⓟ</span>
        </span>
      );
    }

    return (
      <span className="whitespace-nowrap">
        <CountUpNumber
          value={props.value}
          formattingFn={n =>
            formatNumberLocalized(activeLanguage, n, props.signDisplay)
          }
        />
        {'\u00A0'}
        <span className="text-silver font-bold">Ⓟ</span>
      </span>
    );
  }

  if (isValidISOCurrencyCode(props.unit)) {
    if (!animate || isPrivacyGuardEnabled) {
      return (
        <span className="whitespace-nowrap">
          <PII
            type="currency"
            value={formatCurrencyLocalized(
              activeLanguage,
              props.value,
              props.unit,
              props.signDisplay
            )}
          />
        </span>
      );
    }

    return (
      <span className="whitespace-nowrap">
        <CountUpNumber
          value={props.value}
          formattingFn={n =>
            formatCurrencyLocalized(
              activeLanguage,
              n,
              props.unit,
              props.signDisplay
            )
          }
        />
      </span>
    );
  }

  if (
    Object.values(QualificationLevel).includes(props.unit as QualificationLevel)
  ) {
    return (
      <span className="w-fit flex items-center whitespace-nowrap">
        <span className="mr-xxs">
          <PII
            value={formatNumberLocalized(
              activeLanguage,
              props.value,
              props.signDisplay
            )}
            type="number"
          />
        </span>
        <QualificationBadge qualification={props.unit as QualificationLevel} />
      </span>
    );
  }

  return (
    <span className="whitespace-nowrap">
      <PII
        value={formatCustomUnitLocalized(
          activeLanguage,
          props.value,
          props.unit,
          props.signDisplay
        )}
        type="number"
      />
    </span>
  );
}

interface ICountUpNumberProps {
  readonly value: number;
  readonly formattingFn: (n: number) => string;
}

function CountUpNumber(props: ICountUpNumberProps) {
  const start = props.value / 2;
  const end = props.value;
  const duration = 1.5; // seconds
  const decimals = Number.isInteger(props.value) ? 0 : 2;

  return (
    <CountUp
      start={start}
      end={end}
      duration={duration}
      decimals={decimals}
      formattingFn={props.formattingFn}
    />
  );
}

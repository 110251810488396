import { VirtualWarehouseHelpCarousel } from '@pmi.web/react-virtual-warehouse-help';
import { BottomSheetContainer } from 'components/modals';
import { useGlobalConfigsContext } from 'contexts';
import { useBoolean, usePatchOfficeServerState } from 'hooks';
import { fireConfettiGun } from 'libs/effects';
import { trackEvent } from 'libs/telemetry';
import { Timestamp } from 'libs/time';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useEffect } from 'react';

import './VirtualWarehouseHelpModal.scss';

export function VirtualWarehouseHelpModal() {
  const { t, activeLanguage } = useTranslation();
  const {
    app: {
      office: { welcomeVirtualWarehouseDisplayDate }
    },
    promoShop
  } = useGlobalConfigsContext();
  const { patchPreferences } = usePatchOfficeServerState();

  const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(
    _.isUndefined(welcomeVirtualWarehouseDisplayDate)
  );

  const closeModal = () => {
    if (_.isUndefined(welcomeVirtualWarehouseDisplayDate)) {
      patchPreferences({ welcomeVirtualWarehouseDisplayDate: Timestamp.now() });
    }

    trackEvent({ name: 'VW_CloseHelpMessage' });
    close();
  };

  const isFirstTime = _.isUndefined(welcomeVirtualWarehouseDisplayDate);

  useEffect(() => {
    if (isOpen && isFirstTime) {
      setTimeout(() => {
        _.times(2, () => fireConfettiGun('blue'));
      }, 100);
      setTimeout(() => {
        _.times(1, () => fireConfettiGun('green'));
      }, 300);
      setTimeout(() => {
        _.times(2, () => fireConfettiGun('blue'));
      }, 500);
      setTimeout(() => {
        _.times(1, () => fireConfettiGun('green'));
      }, 700);
      setTimeout(() => {
        _.times(1, () => fireConfettiGun('blue'));
      }, 900);

      trackEvent({ name: 'VW_WelcomeMessageDisplay' });
    }
  }, [isFirstTime, isOpen, welcomeVirtualWarehouseDisplayDate]);

  if (!promoShop) {
    return null;
  }

  return (
    <>
      <button
        className="VirtualWarehouseHelpModal__button"
        onClick={() => {
          open();
          trackEvent({ name: 'VW_OpenHelpMessage' });
        }}
      >
        {t('Help')}
      </button>
      <BottomSheetContainer open={isOpen} onDismiss={closeModal}>
        <VirtualWarehouseHelpCarousel
          language={activeLanguage}
          onComplete={closeModal}
        />
      </BottomSheetContainer>
    </>
  );
}

import { Icon, IconName } from 'libs/icons';
import { Month, Timestamp } from 'libs/time';
import { useTranslation } from 'libs/translations';
import { useEffect, useState } from 'react';
import AuthenticationService from 'services/AuthenticationService';

interface IPeriodSelectorProps {
  readonly onPeriodChange: (event: IPeriodSelectorChangeEvent) => void;
}

export interface IPeriodSelectorChangeEvent {
  readonly selectedPeriod: Month;
  readonly historicalMonths: number;
}

const FIRST_OF_TODAYS_MONTH: Month = Month.today();
const MAX_HISTORICAL_MONTHS = 12;

export function computePeriodOptions() {
  const thisMonth = FIRST_OF_TODAYS_MONTH;
  const maxHistoricalMonths =
    AuthenticationService.user && AuthenticationService.user.registrationDate
      ? Math.min(
          Timestamp.diffInMonths(
            AuthenticationService.user.registrationDate,
            Timestamp.now()
          ),
          MAX_HISTORICAL_MONTHS
        )
      : MAX_HISTORICAL_MONTHS;

  const values = [thisMonth];
  let lastMonth = thisMonth;
  for (let i = 0; i < maxHistoricalMonths; i++) {
    lastMonth = lastMonth.previousMonth();
    values.push(lastMonth);
  }

  return values;
}

export function PeriodSelector(props: IPeriodSelectorProps) {
  const { activeLanguage } = useTranslation();
  const periodOptions = computePeriodOptions();
  const [periodOptionIndex, setPeriodOptionIndex] = useState<number>(0);

  const onPeriodChange = (index: number) => {
    setPeriodOptionIndex(index);
    props.onPeriodChange({
      selectedPeriod: periodOptions[index],
      historicalMonths: MAX_HISTORICAL_MONTHS - index
    });
  };

  useEffect(() => {
    onPeriodChange(periodOptionIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white text-primary rounded-md shadow-md flex flex-row items-center relative gap-xs overflow-hidden text-ellipsis w-[120px] select-none">
      <select
        aria-label="period"
        defaultValue={periodOptions[periodOptionIndex].toString()}
        className="p-xs bg-transparent rounded-md w-full appearance-none overflow-hidden text-ellipsis whitespace-nowrap pr-md"
        onChange={event => onPeriodChange(Number(event.target.value))}
      >
        {periodOptions.map((period, index) => (
          <option key={period.toString()} value={index}>
            {period.toLocaleString(activeLanguage)}
          </option>
        ))}
      </select>
      <Icon
        name={IconName.ChevronDown}
        size={16}
        className="pointer-events-none absolute top-3.5 right-xs"
      />
    </div>
  );
}

import { HelperModal } from 'components/dialogs';
import { UserHeader } from 'components/headers';
import { FormattedUnitValue, LabelledText } from 'components/text';
import { PII } from 'features/pii';
import { FixedDate } from 'libs/time';
import { useTranslation } from 'libs/translations';
import { IUserProfile } from 'types';

import { AutoshipStatus } from './AutoshipStatus';

export interface IAutoshipDetails {
  readonly owner: IUserProfile;
  readonly points: number;
  readonly status: 'processed' | 'notProcessed' | 'cancelled' | 'paused';
  readonly lastExecutionDate?: FixedDate;
  readonly nextExecutionDate?: FixedDate;
  readonly type: string;
}

interface IAutoshipDetailsModalProps extends IAutoshipDetails {
  readonly onDismiss: () => void;
}

export function AutoshipDetailsModal(props: IAutoshipDetailsModalProps) {
  const { t, activeLanguage } = useTranslation();

  return (
    <HelperModal onDismiss={props.onDismiss}>
      <h1>{t('Autoship details')}</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-sm">
        <LabelledText
          label={t('Owner')}
          value={
            <UserHeader
              user={props.owner}
              showBadges
              showCheckAssurance
              showQualification
              showNewRegistration
              disableNavigation
              showCountryFlag
            />
          }
        />

        <LabelledText
          label={t('Points')}
          value={<FormattedUnitValue unit="points" value={props.points} />}
        />
        <LabelledText
          label={t('Status')}
          value={<AutoshipStatus status={props.status} showLabel />}
        />
        {props.nextExecutionDate && (
          <LabelledText
            label={t('Next execution date')}
            value={
              <PII
                value={props.nextExecutionDate.toLocaleString(
                  activeLanguage,
                  'medium'
                )}
                type="fixed-date"
              />
            }
          />
        )}
      </div>
    </HelperModal>
  );
}

import { QUALIFICATIONS, QualificationLevel } from 'contants';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

export function useQualificationFilter() {
  const { watch } = useFormContext();

  let qualiLevelFilterFrom = watch('byQualificationFrom');

  if (Number.isNaN(qualiLevelFilterFrom)) {
    qualiLevelFilterFrom = undefined;
  }

  const qualiLevelFilterTo = watch('byQualificationTo');

  const sortedOptions: ReadonlyArray<QualificationLevel> = [
    QualificationLevel.Teampartner,
    QualificationLevel.Manager,
    QualificationLevel.SalesManager,
    QualificationLevel.MarketingManager,
    QualificationLevel.InternationalMarketingManager,
    QualificationLevel.VicePresident,
    QualificationLevel.ExecutiveVicePresident,
    QualificationLevel.PresidentsTeam,
    QualificationLevel.SilverPresidentsTeam,
    QualificationLevel.GoldPresidentsTeam,
    QualificationLevel.PlatinumPresidentsTeam,
    QualificationLevel.ChampionsLeague,
    QualificationLevel.SilverChampionsLeague,
    QualificationLevel.GoldChampionsLeague,
    QualificationLevel.PlatinumChampionsLeague,
    QualificationLevel.Champion
  ];

  const qualiLevelOptionsFrom = sortedOptions.filter(
    option => QUALIFICATIONS[option].numericValue < qualiLevelFilterTo
  );

  const qualiLevelOptionsTo = _.isUndefined(qualiLevelFilterFrom)
    ? sortedOptions
    : sortedOptions.filter(
        option => QUALIFICATIONS[option].numericValue > qualiLevelFilterFrom
      );

  return {
    qualiLevelFilterFrom,
    qualiLevelOptionsFrom,
    qualiLevelFilterTo,
    qualiLevelOptionsTo
  };
}

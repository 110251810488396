import { Timestamp } from 'libs/time';
import { useEffect, useState } from 'react';
import { CommitmentStatus, ICommitment } from 'types';

import { isCommitmentExpired } from '../components/commitments-list/CommitmentHelper';

export function useCommitmentStatus(commitment: ICommitment) {
  const [status, setStatus] = useState<CommitmentStatus>(commitment.status);

  useEffect(() => {
    if (status !== CommitmentStatus.Active) {
      return;
    }

    const checkForExpiration = () => {
      if (
        isCommitmentExpired(commitment) &&
        commitment.status === CommitmentStatus.Active
      ) {
        setStatus(CommitmentStatus.Expired);
      }
    };

    const scheduleTime =
      commitment.expiresOn.getTime() - Timestamp.now().getTime();
    const interval = setInterval(checkForExpiration, scheduleTime);

    checkForExpiration();

    return () => {
      clearInterval(interval);
    };
  }, [commitment, status]);

  return status;
}

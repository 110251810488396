import { HelperModal } from 'components/dialogs';
import { usePII } from 'features/pii';
import { useBoolean } from 'hooks';
import { Icon, IconName } from 'libs/icons';
import { Timestamp } from 'libs/time';
import { useTranslation } from 'libs/translations';

export function TicketPurchaseDate(props: { readonly date: Timestamp }) {
  const { t, activeLanguage } = useTranslation();
  const { pii } = usePII();

  const [isOpen, { setFalse: close, setTrue: open }] = useBoolean(false);

  return (
    <>
      <button
        className="cursor-help flex flex-row items-center text-left gap-xs"
        onClick={open}
      >
        <span>
          <Icon name={IconName.ShoppingBag} size={16} />
        </span>
        <span className="hidden lg:inline-block">
          {t('Purchased on {{- date}}', {
            date: pii(
              props.date.toLocaleDateString(activeLanguage, 'long'),
              'fixed-date'
            )
          })}
        </span>
        <span className="lg:hidden inline-block">
          {pii(
            props.date.toLocaleDateString(activeLanguage, 'short'),
            'fixed-date'
          )}
        </span>
      </button>
      {isOpen && (
        <HelperModal onDismiss={close}>
          <div className="flex flex-row items-center justify-center flex-wrap gap-xs">
            <Icon name={IconName.ShoppingBag} size={24} />
            {t('This ticket was purchased on {{- date}}', {
              date: pii(
                props.date.toLocaleDateString(activeLanguage, 'long'),
                'timestamp'
              )
            })}
          </div>
        </HelperModal>
      )}
    </>
  );
}

import { Timestamp } from 'libs/time';
import { IOrder } from 'types';

import { mapAPIOrder, mapPaginatedOrders } from './mappers';
import { IOrderPaginatedResults, IOrderApi } from './types';
import { execGet } from '../RequestUtils';

const API_URL = `${process.env.REACT_APP_ORDERS_API}`;

/**
 * Hardcode "from" to October 2023 because that's the moment when the Orders
 * API started to be used to create all types of orders. Before that, mainly
 * event orders where available. Setting "from", we avoid confusing users
 * that won't see for example their webshop orders before this date.
 */
const ORDERS_FROM_FILTER = Timestamp.createOrThrow('2023-10-30T00:00:00.000Z')
  .getEpochSeconds()
  .toString();

export async function getOrder(id: string): Promise<IOrder> {
  const apiResponse = await execGet<IOrderApi>(`${API_URL}/${id}`);
  return mapAPIOrder(apiResponse);
}

export async function getOrders(
  page = 1,
  perPage = 20
): Promise<IOrderPaginatedResults<IOrder>> {
  const params = new URLSearchParams();
  params.set('page', page.toString());
  params.set('perPage', perPage.toString());
  params.set('sortOrder', 'descending');
  params.set('from', ORDERS_FROM_FILTER);

  const response = await execGet<IOrderPaginatedResults<IOrderApi>>(
    `${API_URL}?${params.toString()}`
  );

  return mapPaginatedOrders(response);
}

import { FixedDate, Month, Timestamp } from 'libs/time';
import _ from 'lodash';
import { IHistoryVolume, IProgressDetail, IUserBusinessData } from 'types';
import { parseQualificationLevel, normalizePercentageValue } from 'utils';

import { IBusinessMetricProgressDetailsApi } from './types/IBusinessMetricProgressDetailsApi';
import { IHistoryVolumeApi } from './types/IHistoryVolumeApi';
import { IUserBusinessDataApi } from './types/IUserBusinessDataApi';

export function parseAPIUserBusinessData(
  data: IUserBusinessDataApi,
  calculationDate?: string,
  qualification?: string
): IUserBusinessData {
  const qualificationLevel = _.isUndefined(data.qualificationProgress)
    ? undefined
    : parseQualificationLevel(data.qualificationProgress.qualification);

  const result: IUserBusinessData = {
    userId: data.userId + '',
    calculationDate: _.isUndefined(calculationDate)
      ? undefined
      : Timestamp.create(calculationDate),

    // Qualification
    currentQualificationLevel: parseQualificationLevel(qualification ?? ''),
    nextQualificationLevel: qualificationLevel,
    nextQualificationProgress: {
      percent: normalizePositivePercentage(
        data.qualificationProgress?.percent ?? 0
      ),
      details: _.isUndefined(data.qualificationProgress)
        ? []
        : data.qualificationProgress.details.map(
            parseQualificationProgressDetails
          )
    },
    qualificationTeam: data.teamQualifications.map(tq => {
      const qualification = parseQualificationLevel(tq.qualification);

      if (_.isUndefined(qualification)) {
        throw new Error('Qualification level in the team cannot be undefined!');
      }

      return {
        count: tq.count ?? 0,
        qualification
      };
    }),
    qualificationHistory: data.qualificationHistory.map(item => {
      return {
        date: Month.createOrThrow(item.date),
        qualification: parseQualificationLevel(item.qualification),
        newHighestAchievemet: item.newRankAchieved
      };
    }),

    // Check Assurance
    checkAssuranceProgress: data.checkAssuranceProgress
      ? {
          percent: normalizePositivePercentage(
            data.checkAssuranceProgress.percent ?? 0
          ),
          options: data.checkAssuranceProgress.options.map(o => ({
            percent: normalizePositivePercentage(o.percent ?? 0),
            details: o.details.map(d => ({
              ...d,
              value: d.value ?? 0,
              target: d.target ?? 0
            }))
          }))
        }
      : undefined,

    // First Qualification
    firstQualificationProgress: data.firstQualificationProgress
      ? {
          percent: normalizePositivePercentage(
            data.firstQualificationProgress.percent ?? 0
          ),
          options: data.firstQualificationProgress.options.map(o => ({
            percent: normalizePositivePercentage(o.percent ?? 0),
            details: o.details.map(d => ({
              ...d,
              value: d.value ?? 0,
              target: d.target ?? 0
            }))
          }))
        }
      : undefined,

    // Autoships
    autoshipsOpenTurnover: data.openAutoshipVolume,
    autoshipsInStructure: {
      current: data.structureAutoships.current ?? 0,
      lastMonth: data.structureAutoships.lastMonth ?? 0,
      lastYear: data.structureAutoships.lastYear ?? 0,
      history: parseHistoryData(data.structureAutoships.history)
    },
    autoshipsInFirstline: {
      current: data.firstlineAutoships.current ?? 0,
      history: parseHistoryData(data.firstlineAutoships.history)
    },

    gainedAutoshipsInStructure: data.gainedAutoshipCountStructure
      ? {
          history: parseHistoryData(data.gainedAutoshipCountStructure.history)
        }
      : undefined,
    lostAutoshipsInStructure: data.lostAutoshipCountStructure
      ? {
          history: parseHistoryData(data.lostAutoshipCountStructure.history)
        }
      : undefined,

    autoshipsPersonalList: data.ownAutoships.map(d => ({
      points: d.points ?? 0,
      status: d.status,
      lastExecutionDate: d.lastExecutionDate
        ? FixedDate.createOrThrow(d.lastExecutionDate)
        : undefined,
      nextExecutionDate: d.nextExecutionDate
        ? FixedDate.createOrThrow(d.nextExecutionDate)
        : undefined,
      type: d.type
    })),

    // Turnover
    turnoverFromSelf: {
      current: data.ownVolume.current ?? 0,
      lastMonth: data.ownVolume.lastMonth ?? 0,
      lastYear: data.ownVolume.lastYear ?? 0,
      history: parseHistoryData(data.ownVolume.history)
    },
    turnoverFromGroup: {
      current: data.groupVolume.current ?? 0,
      history: parseHistoryData(data.groupVolume.history)
    },
    turnoverFromSixLevel: {
      current: data.sixLevelVolume.current ?? 0,
      lastMonth: data.sixLevelVolume.lastMonth ?? 0,
      lastYear: data.sixLevelVolume.lastYear ?? 0,
      history: parseHistoryData(data.sixLevelVolume.history)
    },
    turnoverFromStructure: {
      current: data.structureVolume.current ?? 0,
      lastMonth: data.structureVolume.lastMonth ?? 0,
      lastYear: data.structureVolume.lastYear ?? 0,
      history: parseHistoryData(data.structureVolume.history)
    },

    // Registrations
    customerRegistrations: {
      firstline: {
        current: data.ownCustomerRegistrations.current ?? 0,
        lastMonth: data.ownCustomerRegistrations.lastMonth ?? 0,
        lastYear: data.ownCustomerRegistrations.lastYear ?? 0,
        history: parseHistoryData(data.ownCustomerRegistrations.history)
      },
      structure: {
        current: data.customerRegistrations.current ?? 0,
        history: parseHistoryData(data.customerRegistrations.history)
      }
    },
    teampartnerRegistrations: {
      firstline: {
        current: data.ownTeampartnerRegistrations.current ?? 0,
        lastMonth: data.ownTeampartnerRegistrations.lastMonth ?? 0,
        lastYear: data.ownTeampartnerRegistrations.lastYear ?? 0,
        history: parseHistoryData(data.ownTeampartnerRegistrations.history)
      },
      structure: {
        current: data.teampartnerRegistrations.current ?? 0,
        lastMonth: data.teampartnerRegistrations.lastMonth ?? 0,
        lastYear: data.teampartnerRegistrations.lastYear ?? 0,
        history: parseHistoryData(data.teampartnerRegistrations.history)
      }
    },
    quickstarterRegistrations: {
      firstline: {
        current: data.quickstarterCountFirstline.current ?? 0,
        history: parseHistoryData(data.quickstarterCountFirstline.history)
      },
      structure: {
        current: data.quickstarterCountStructure.current ?? 0,
        history: parseHistoryData(data.quickstarterCountStructure.history)
      }
    },
    sponsorActivityRegistrations: data.ownTeampartnerRegistrationsWith100Points
      ? {
          current: data.ownTeampartnerRegistrationsWith100Points.current ?? 0,
          lastMonth:
            data.ownTeampartnerRegistrationsWith100Points.lastMonth ?? 0,
          lastYear: data.ownTeampartnerRegistrationsWith100Points.lastYear ?? 0,
          history: parseHistoryData(
            data.ownTeampartnerRegistrationsWith100Points.history ?? []
          )
        }
      : undefined,

    // Car Bonus
    carBonusTotal: data.carBonusSaveTotal,
    carBonusCurrency: data.retailIncome.currency,
    carBonusHistory: !_.isUndefined(data.carBonusSaveDetails)
      ? parseHistoryData(data.carBonusSaveDetails)
      : undefined,

    // Payout
    payoutCurrency: data.payout?.currency ?? undefined,
    payoutCurrentValue: data.payout?.current ?? undefined,
    payoutHistory:
      !_.isUndefined(data.payout) && !_.isUndefined(data.payout.history)
        ? parseHistoryData(data.payout.history)
        : undefined
  };

  return result;
}

function parseHistoryData(historyData: IHistoryVolumeApi[]): IHistoryVolume[] {
  return historyData.map(h => ({
    volume: h.volume ?? 0,
    date: Month.createOrThrow(h.date)
  }));
}

function parseQualificationProgressDetails(
  details: IBusinessMetricProgressDetailsApi
): IProgressDetail {
  let normalizedValue = details.value ?? 0;
  let normalizedTarget = details.target ?? 0;

  if (details.unit === 'percent') {
    normalizedValue = normalizePositivePercentage(normalizedValue);
    normalizedTarget = normalizePositivePercentage(normalizedTarget);
  }

  return {
    ...details,
    value: normalizedValue,
    target: normalizedTarget
  };
}

function normalizePositivePercentage(value: number) {
  let normalizedValue = normalizePercentageValue(value);
  normalizedValue =
    normalizedValue > 100 ? 100 : normalizedValue < 0 ? 0 : normalizedValue;

  return normalizedValue;
}

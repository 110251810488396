import { Timestamp } from 'libs/time';
import _ from 'lodash';
import {
  IPaginatedResponse,
  IWalletAccount,
  IWalletTransaction,
  isValidISOCurrencyCode
} from 'types';

import { IWalletAccountApi } from './types/IWalletAccountApi';
import { IWalletTransactionApi } from './types/IWalletTransactionApi';
import { IWalletTransactionsApi } from './types/IWalletTransactionsApi';

export function parseWalletAccount(
  apiWalletAccount: IWalletAccountApi
): IWalletAccount {
  const currency = isValidISOCurrencyCode(apiWalletAccount.currency)
    ? apiWalletAccount.currency
    : undefined;

  if (_.isUndefined(currency)) {
    throw new Error('Invalid currency ' + apiWalletAccount.currency);
  }

  const maxAllowedBalance = isNullOrUndefined(
    apiWalletAccount.maxAllowedBalance
  )
    ? undefined
    : apiWalletAccount.maxAllowedBalance;

  return {
    ...apiWalletAccount,
    currency,
    maxAllowedBalance
  };
}

export function parseWalletTransactions(
  apiWalletTransactions: IWalletTransactionsApi
): IPaginatedResponse<IWalletTransaction> {
  // ... because the first page is number 0
  const pageNumber = apiWalletTransactions.transactionsPageNumber + 1;
  const pageSize = apiWalletTransactions.transactionsPageSize;
  const totalCount = apiWalletTransactions.totalNumberOfTransactions;
  const totalPages = Math.ceil(totalCount / pageSize);

  const hasPreviousPage = pageNumber > 1;
  const hasNextPage = pageNumber < totalPages;

  const data = apiWalletTransactions.transactions.map(parseWalletTransaction);

  return {
    data,
    hasNextPage,
    hasPreviousPage,
    pageNumber,
    pageSize,
    totalCount,
    totalPages
  };
}

function parseWalletTransaction(
  apiWalletTransation: IWalletTransactionApi
): IWalletTransaction {
  const {
    amount,
    currency,
    conversionCurrency,
    currencyConversionAmount,
    createdAt,
    reference,
    transactionId,
    type,
    status,
    note,
    reason,
    otherSideAccountId,
    otherSideAccountName
  } = apiWalletTransation;

  return {
    amount,
    currency,
    originalAmount: currencyConversionAmount ?? undefined,
    originalCurrency:
      conversionCurrency && isValidISOCurrencyCode(conversionCurrency)
        ? conversionCurrency
        : undefined,
    createdAt: Timestamp.createOrThrow(createdAt),
    reference,
    transactionId,
    type,
    status: status.toLowerCase(),
    note,
    reason,
    otherSideAccountId,
    otherSideAccountName
  };
}

function isNullOrUndefined(value: unknown): value is null | undefined {
  return _.isNull(value) || _.isUndefined(value);
}

import { QualificationBadge } from 'components/badges';
import { RankBadge } from 'components/badges/qualification/RankBadge';
import { QualificationLevel } from 'contants';
import _ from 'lodash';
import { IUserProfile } from 'types';

interface IQualificationColumProps {
  readonly userProfile: IUserProfile;
}

export function CurrentQualificationColumn(props: IQualificationColumProps) {
  if (props.userProfile.isDiscountCustomer) {
    return <span></span>;
  }

  return (
    <QualificationColumn
      currentMonth
      qualificationLevel={props.userProfile.qualification}
    />
  );
}

export function PreviousMonthQualificationColumn(
  props: IQualificationColumProps
) {
  if (props.userProfile.isDiscountCustomer) {
    return <span></span>;
  }

  return (
    <QualificationColumn
      previousMonth
      qualificationLevel={
        props.userProfile.businessDataSummary?.qualificationLastMonth
      }
    />
  );
}

export function HighesthQualificationColumn(props: IQualificationColumProps) {
  if (props.userProfile.isDiscountCustomer) {
    return <span></span>;
  }

  return (
    <QualificationColumn rank qualificationLevel={props.userProfile.rank} />
  );
}

interface IQualificationColumnProps {
  readonly qualificationLevel?: QualificationLevel;
  readonly currentMonth?: boolean;
  readonly previousMonth?: boolean;
  readonly rank?: boolean;
}

function QualificationColumn(props: IQualificationColumnProps) {
  if (_.isUndefined(props.qualificationLevel)) {
    return <span></span>;
  }

  if (props.rank) {
    return <RankBadge rank={props.qualificationLevel} />;
  }

  return (
    <QualificationBadge
      qualification={props.qualificationLevel}
      currentMonth={props.currentMonth}
      previousMonth={props.previousMonth}
    />
  );
}

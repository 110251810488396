import { Month } from 'libs/time';
import { useEffect, useState } from 'react';
import { fetchFirstlineOf } from 'services';
import { IPaginatedResponse, IUserProfile } from 'types';

interface IBusinessDataHookOptions {
  readonly userId: string;
  readonly historicalMonths?: number;
  // If undefined, use the current month
  readonly month?: Month;
}

export function useFirstlineData(options: IBusinessDataHookOptions) {
  const [firstlineData, setFirstlineData] =
    useState<IPaginatedResponse<IUserProfile>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    const period = options.month ?? Month.today();

    setLoading(true);
    fetchFirstlineOf(options.userId, {
      pageSize: 10,
      period
    })
      .then(data => {
        setFirstlineData(data);
        setError(undefined);
      })
      .catch(err => {
        setFirstlineData(undefined);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [options.historicalMonths, options.month, options.userId]);

  return {
    firstlineData,
    firstlineDataError: error,
    firstlineDataLoading: loading
  };
}

import { RadioGroup } from '@headlessui/react';
import { Modal } from 'components/dialogs';
import { usePopCardContext } from 'components/popcards/PopCardContext';
import { LoadingSpinner } from 'components/spinners';
import { Disclaimer } from 'components/text';
import { useGlobalConfigsContext } from 'contexts';
import { usePatchOfficeServerState } from 'hooks';
import { trackEvent, trackException } from 'libs/telemetry';
import { Timestamp } from 'libs/time';
import { triggerErrorToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { FC, useMemo, useState } from 'react';
import AuthenticationService from 'services/AuthenticationService';
import { BonusPayoutMethod, DirectCashPayoutMethod } from 'types';

import { BonusPayoutOption } from './BonusPayoutOption';
import { isBonusPayoutSelectionDateValid } from './BonusPayoutsHelper';
import { usePayoutsContext } from '../../contexts/PayoutsContext';
import { PayquickerDirectCashLegalAgreement } from '../payquicker/PayquickerDirectCashLegalAgreement';

export const BonusPayouts: FC = () => {
  const { t } = useTranslation();
  const {
    app: {
      office: { lastBonusPayoutUpdateDate }
    },
    device: { supportsHover }
  } = useGlobalConfigsContext();
  const { patchPreferences } = usePatchOfficeServerState();
  const { queuePopCard } = usePopCardContext();
  const { contextState, payoutDetails, updatePayouts } = usePayoutsContext();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [openPayquickerLegalAgreement, setOpenPayquickerLegalAgreement] =
    useState<boolean>(false);

  const hasAvailableMethods = useMemo(() => {
    const availableMethodLength = (
      payoutDetails?.availableBonusPayoutMethods ?? []
    ).length;
    const lockedMethodsLength = (
      payoutDetails?.availableBonusPayoutMethods ?? []
    ).filter(method => method.locked === true).length;

    return availableMethodLength && availableMethodLength > lockedMethodsLength;
  }, [payoutDetails?.availableBonusPayoutMethods]);

  const isPayQuickerRegistrationCompleted = useMemo(
    () => payoutDetails?.payQuickerAccount?.registrationStatus === 'Completed',
    [payoutDetails]
  );

  const onSelectionChange = async (bonusMethod: BonusPayoutMethod) => {
    const preselectedMethod = payoutDetails?.selectedBonusPayoutMethod;

    if (_.isUndefined(preselectedMethod) || preselectedMethod === bonusMethod) {
      return;
    }

    const alreadyAcceptedPayquickerAgreement =
      payoutDetails?.directCashState.active &&
      payoutDetails?.selectedDirectCashPayoutMethod ===
        DirectCashPayoutMethod.PayQuicker;

    if (
      bonusMethod === BonusPayoutMethod.PayQuicker &&
      !alreadyAcceptedPayquickerAgreement
    ) {
      setOpenPayquickerLegalAgreement(true);
      trackEvent({
        name: 'BonusPayouts_OpenedPayQuickerAgreement'
      });
      return;
    }

    try {
      setLoading(true);
      await updatePayouts({ bonusMethod: bonusMethod });

      trackEvent({
        name: 'BonusPayouts_OptionChanged',
        properties: {
          selectMethod: bonusMethod
        }
      });

      const bonusPayoutUpdateDate = Timestamp.now();
      if (isBonusPayoutSelectionDateValid(bonusPayoutUpdateDate)) {
        queuePopCard({
          title: t("You're all set for your new payout method!"),
          description: t(
            'The upcoming bonus will be processed as usual, but your next bonus payout will be processed through the newly selected method.'
          ),
          id: _.uniqueId(),
          cta: t('Close'),
          ctaAction: _.noop
        });
      }

      patchPreferences({
        lastBonusPayoutUpdateDate: bonusPayoutUpdateDate
      });
    } catch (err) {
      console.error('Failed to update bonus payout method', err);
      trackException(
        err,
        'BonusPayouts',
        `Failed while changing bonus method to "${bonusMethod}"`
      );
      triggerErrorToast(t('Something went wrong.'));
    } finally {
      setLoading(false);
    }
  };

  const refusePQAgreement = () => {
    setLoading(false);
    setOpenPayquickerLegalAgreement(false);
    trackEvent({
      name: 'BonusPayouts_RefusedPayQuickerAgreement'
    });
  };

  const acceptPQAgreement = async () => {
    setOpenPayquickerLegalAgreement(false);

    trackEvent({
      name: 'BonusPayouts_AcceptedPayQuickerAgreement'
    });

    try {
      setLoading(true);
      await updatePayouts({ bonusMethod: BonusPayoutMethod.PayQuicker });

      if (
        !_.isUndefined(AuthenticationService.user) &&
        !_.isUndefined(AuthenticationService.user.email) &&
        !isPayQuickerRegistrationCompleted
      ) {
        queuePopCard({
          title: AuthenticationService.user.email,
          description: t(
            "You successfully upgraded to PM Pay+! However, your registration still needs to be completed. Along with your next payout, you will receive an email with further instructions on how to register. Don't forget to complete your registration so you can access your future payouts."
          ),
          id: _.uniqueId(),
          imgUri: '/imgs/promo/Email.svg',
          cta: t('Close'),
          ctaAction: _.noop
        });
      }

      trackEvent({
        name: 'BonusPayouts_OptionChanged',
        properties: {
          selectMethod: BonusPayoutMethod.PayQuicker
        }
      });

      const bonusPayoutUpdateDate = Timestamp.now();
      if (isBonusPayoutSelectionDateValid(bonusPayoutUpdateDate)) {
        queuePopCard({
          title: t("You're all set for your new payout method!"),
          description: t(
            'The upcoming bonus will be processed as usual, but your next bonus payout will be processed through the newly selected method.'
          ),
          id: _.uniqueId(),
          cta: t('Close'),
          ctaAction: _.noop
        });
      }

      patchPreferences({
        lastBonusPayoutUpdateDate: bonusPayoutUpdateDate
      });
    } catch (err) {
      console.error('Failed to update bonus payout method', err);
      trackException(
        err,
        'BonusPayouts',
        `Failed while changing bonus method to "Payquicker" after accepting legal agreement`
      );
      triggerErrorToast(t('Something went wrong.'));
    } finally {
      setLoading(false);
    }
  };

  if (!payoutDetails || contextState.loading) {
    return null;
  }

  return (
    <section className="space-y-sm bg-white p-sm shadow-md rounded-md relative">
      {isLoading && (
        <div className="absolute inset-0 pmi-glass-white flex items-center justify-center z-1">
          <LoadingSpinner smallSize />
        </div>
      )}

      <h2 className="text-primary">{t('Bonus payouts')}</h2>

      {!hasAvailableMethods && (
        <Disclaimer
          message={t(
            'There are no options available right now. Please come back later or contact the support team.'
          )}
        />
      )}

      {hasAvailableMethods && (
        <>
          <p>
            {t('Choose how you would like to receive your bonus payments.')}
          </p>

          {!_.isUndefined(lastBonusPayoutUpdateDate) &&
            contextState.payoutUpdated && (
              <Disclaimer
                message={t(
                  'The upcoming bonus will be processed as usual, but your next bonus payout will be processed through the newly selected method.'
                )}
              />
            )}

          {!isPayQuickerRegistrationCompleted &&
            payoutDetails.selectedBonusPayoutMethod ===
              BonusPayoutMethod.PayQuicker && (
              <Disclaimer
                type="warning"
                message={t(
                  'PM Pay+ is not activated yet. Complete your registration to access your future payouts.'
                )}
              />
            )}

          <RadioGroup
            value={payoutDetails.selectedBonusPayoutMethod}
            onChange={isLoading ? undefined : onSelectionChange}
            className={'mt-sm'}
          >
            <div className="grid grid-cols xs:grid-cols-2 xl:grid-cols-4 gap-xs xs:gap-md">
              {_.sortBy(payoutDetails.availableBonusPayoutMethods, [
                'locked'
              ]).map(bonusMethod => (
                <BonusPayoutOption
                  key={bonusMethod.bonusPayoutMethod}
                  option={bonusMethod.bonusPayoutMethod}
                  locked={bonusMethod.locked}
                  region={bonusMethod.region}
                />
              ))}
            </div>
          </RadioGroup>
        </>
      )}

      <Modal
        hideCloseBtn
        open={openPayquickerLegalAgreement}
        onClose={() => setOpenPayquickerLegalAgreement(false)}
      >
        <PayquickerDirectCashLegalAgreement origin="bonus" />
        <div className="flex flex-row flex-wrap justify-evenly mt-md">
          <button
            className="rounded-full px-md py-sm bg-primary text-white"
            onClick={acceptPQAgreement}
          >
            {t('I accept')}
          </button>
          <button
            className={`text-primary ${
              supportsHover ? 'hover:text-active' : 'text-primary'
            }`}
            onClick={refusePQAgreement}
          >
            {t('Go back')}
          </button>
        </div>
      </Modal>
    </section>
  );
};

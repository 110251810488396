import {
  parseSupportedLanguage,
  SupportedLanguage
} from '@pmi.web/localization';
import enUS from 'assets/i18n/en-US.json';
import { IS_PROD_BUILD } from 'contants';
import i18n, { use } from 'i18next';
// eslint-disable-next-line no-restricted-imports
import { initReactI18next } from 'react-i18next';

const DEFAULT_NAMESPACE = 'pmi.web.office';

export async function initTranslations(): Promise<void> {
  if (!i18n.isInitialized) {
    await use(initReactI18next).init({
      /**
       * i18next options
       */
      debug: !IS_PROD_BUILD,
      fallbackLng: 'en-US',
      ns: DEFAULT_NAMESPACE,
      resources: {
        'en-US': {
          [DEFAULT_NAMESPACE]: enUS
        }
      },
      keySeparator: false,
      nsSeparator: false,
      /**
       * react-i18next options
       */
      react: {
        bindI18n: 'languageChanged',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['h1', 'h2', 'p', 'ul', 'li', 'strong']
      }
    });
  }

  addTranslationsFor(SupportedLanguage.English);
}

export async function changeTranslationsLanguage(language: string) {
  const supportedLanguage = parseSupportedLanguage(language);
  await addTranslationsFor(supportedLanguage);
  // eslint-disable-next-line import/no-named-as-default-member
  return i18n.changeLanguage(supportedLanguage);
}

async function addTranslationsFor(language: SupportedLanguage): Promise<void> {
  if (i18n.hasResourceBundle(language, DEFAULT_NAMESPACE)) {
    return Promise.resolve();
  }

  const translations = await loadTranslationsFor(language);
  i18n.addResourceBundle(language, DEFAULT_NAMESPACE, translations, true, true);
}

async function loadTranslationsFor(language: SupportedLanguage) {
  try {
    const module = await import(`assets/i18n/${language}.json`);
    return module.default;
  } catch (err) {
    throw new Error(
      `Cannot load translations for "${language}". The JSON file seems to be missing.`,
      { cause: err }
    );
  }
}

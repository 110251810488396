/* eslint-disable @miovision/disallow-date/no-new-date */
import { PrimaryButton, Typography } from '@pmi.web/react-common';
import { BottomSheetContainer } from 'components/modals';
import { useGlobalConfigsContext } from 'contexts';
import { useBoolean, usePatchOfficeServerState } from 'hooks';
import { fireConfettiGun } from 'libs/effects';
import { Icon, IconName, RouteIcon, RouteIconName } from 'libs/icons';
import { trackEvent } from 'libs/telemetry';
import { Timestamp } from 'libs/time';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Sat Sep 21 2024 13:00:00 GMT+0200 (Central European Summer Time)
// new Date('2024-09-21T11:30:00.000Z').getTime()
const BIG_RELEASE_DATE = 1726918200000;
const TODAY = new Date().getTime();
const TIME_TO_ANNOUNCE_IT = TODAY >= BIG_RELEASE_DATE;

export function ShareBasketMarketingPopup() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    app: {
      office: { welcomeNewShareBasketsDisplayDate }
    },
    promoShop
  } = useGlobalConfigsContext();
  const { patchPreferences } = usePatchOfficeServerState();

  const isShareBasketsFeatureAvailable = useMemo(() => {
    return promoShop && promoShop.id;
  }, [promoShop]);

  const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);
  const [shown, setShown] = useState(false);

  const closeModal = useCallback(() => {
    if (_.isUndefined(welcomeNewShareBasketsDisplayDate)) {
      patchPreferences({ welcomeNewShareBasketsDisplayDate: Timestamp.now() });
    }

    trackEvent({ name: 'ShareBaskets_CloseWelcomeMessage' });
    close();
  }, [close, patchPreferences, welcomeNewShareBasketsDisplayDate]);

  const openModal = useCallback(() => {
    trackEvent({ name: 'ShareBaskets_OpenWelcomeMessage' });
    setShown(true);
    open();

    setTimeout(() => {
      _.times(2, () => fireConfettiGun('blue'));
    }, 100);
    setTimeout(() => {
      _.times(1, () => fireConfettiGun('green'));
    }, 300);
    setTimeout(() => {
      _.times(2, () => fireConfettiGun('blue'));
    }, 500);
    setTimeout(() => {
      _.times(1, () => fireConfettiGun('green'));
    }, 700);
    setTimeout(() => {
      _.times(1, () => fireConfettiGun('blue'));
    }, 900);
  }, [open]);

  useEffect(() => {
    if (
      !shown &&
      isShareBasketsFeatureAvailable &&
      _.isUndefined(welcomeNewShareBasketsDisplayDate) &&
      TIME_TO_ANNOUNCE_IT
    ) {
      openModal();
    }
  }, [
    isShareBasketsFeatureAvailable,
    open,
    openModal,
    shown,
    welcomeNewShareBasketsDisplayDate
  ]);

  if (!isShareBasketsFeatureAvailable || (shown && !isOpen)) {
    return null;
  }

  return (
    <BottomSheetContainer open={isOpen} onDismiss={closeModal} maxWidth={416}>
      <div className="flex flex-col w-full items-center">
        <RouteIcon name={RouteIconName.Sales} size={62} />

        <h1 className="mt-sm">{t('Share Baskets')}</h1>

        <div className="text-silver text-center mt-sm">
          <Typography variant="p">
            {t(
              'Meet our New Share Baskets feature which lets you expand your business in just a few clicks:'
            )}
          </Typography>
        </div>

        <div className="flex flex-row items-start gap-sm mt-lg">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="text-primary flex-none"
          >
            <rect
              x="0.837208"
              y="0.837296"
              width="20.3189"
              height="20.3189"
              rx="10.1594"
              stroke="currentColor"
              strokeWidth="1.35459"
            />
            <path
              d="M11.6264 7.37902C11.6264 7.02573 11.3396 6.73886 10.9863 6.73886C10.633 6.73886 10.3461 7.02573 10.3461 7.37902L10.3475 10.3566L7.36999 10.358C7.0167 10.358 6.72982 10.6449 6.72982 10.9981C6.72982 11.3514 7.0167 11.6383 7.36999 11.6383L10.3475 11.6369L10.3489 14.6144C10.3489 14.9677 10.6358 15.2546 10.9891 15.2546C11.3424 15.2546 11.6293 14.9677 11.6293 14.6144L11.6279 11.6369L14.6054 11.6355C14.9587 11.6355 15.2456 11.3486 15.2456 10.9953C15.2456 10.642 14.9587 10.3552 14.6054 10.3552L11.6279 10.3566L11.6264 7.37902Z"
              fill="currentColor"
            />
          </svg>

          <Typography variant="p">
            {t(
              'You can create custom baskets for your team partners, customers or onboard potential customers.'
            )}
          </Typography>
        </div>

        <div className="flex flex-row items-start gap-sm mt-sm pt-sm border-t border-lightGray">
          <Icon name={IconName.Share} size={24} className="text-primary" />
          <Typography variant="p">
            {t('Share your curated baskets and easily track their status.')}
          </Typography>
        </div>

        <div className="mt-md">
          <PrimaryButton
            onClick={() => {
              closeModal();
              navigate('/sales');
            }}
          >
            {t('Check it out!')}
          </PrimaryButton>
        </div>
      </div>
    </BottomSheetContainer>
  );
}

import { LoadingSpinner } from 'components/spinners';
import { Month } from 'libs/time';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { HiOutlineArrowsRightLeft } from 'react-icons/hi2';
import AuthenticationService from 'services/AuthenticationService';
import { IWalletTransaction } from 'types';
import { padWithZeros } from 'utils';

import { WalletTransactionItem } from './WalletTransactionItem';
import { useWalletTransactions } from '../hooks/useWalletTransactions';

export function WalletTransactionsList() {
  const { t, activeLanguage } = useTranslation();
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useWalletTransactions(AuthenticationService.user.userId);

  const sentryRef = useRef<HTMLDivElement>(null);

  const transactionList = useMemo(() => {
    if (data) {
      return _.flatten(data.pages.map(t => t.data));
    }
  }, [data]);

  const transactions = useMemo(() => {
    const transactions: [Month, IWalletTransaction[]][] = [];

    if (!transactionList) {
      return;
    }

    const transactionsMap = new Map<string, IWalletTransaction[]>();
    transactionList.forEach(transation => {
      const { month, year } = transation.createdAt;
      const period = `${year}-${padWithZeros(month, 2)}-01`;
      transactionsMap.set(period, [
        ...(transactionsMap.get(period) ?? []),
        transation
      ]);
    });

    transactionsMap.forEach((transactionList, key) => {
      transactions.push([Month.createOrThrow(key), transactionList]);
    });

    return transactions;
  }, [transactionList]);

  const loadMoreData = useCallback(() => {
    if (!hasNextPage || isFetchingNextPage) {
      return;
    }

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    const ref = sentryRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadMoreData();
        }
      },
      {
        threshold: 0.1
      }
    );

    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [loadMoreData]);

  if (!transactionList) {
    return null;
  }

  return (
    <section>
      <h2>{t('Transations History')}</h2>

      {transactions?.map(([month, monthTransactions]) => {
        return (
          <div key={month.toString()} className="mt-md first-of-type:mt-0">
            {!month.isTodaysMonth() && (
              <h3 className="text-silver">
                {month.toLocaleString(activeLanguage)}
              </h3>
            )}
            <ul>
              {monthTransactions.map(transaction => (
                <li key={transaction.transactionId}>
                  <WalletTransactionItem {...transaction} />
                </li>
              ))}
            </ul>
          </div>
        );
      })}

      {hasNextPage && (
        <div className="w-full flex justify-center p-md" ref={sentryRef}>
          <LoadingSpinner smallSize />
        </div>
      )}

      {transactions?.length === 0 && (
        <div className="text-silver flex flex-col justify-center items-center gap-xs">
          <HiOutlineArrowsRightLeft size={24} />
          <p>{t('No transactions yet')}</p>
        </div>
      )}
    </section>
  );
}

import { Menu, Transition } from '@headlessui/react';
import { UserHeader } from 'components/headers';
import { UserAvatar, UserAvatarSkeleton } from 'components/icons';
import { SignoutButton } from 'components/SignoutButton';
import { NotificationsCenter } from 'features/notifications';
import { PIIBadge } from 'features/pii';
import { PowerUserActiveFeatures } from 'features/power-user';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link, useNavigate } from 'react-router-dom';
import { fetchUserProfile } from 'services';
import AuthenticationService from 'services/AuthenticationService';
import { IUserProfile } from 'types';

import { SearchButton } from './buttons/search-button/SearchButton';
import { PMLogo } from './logos';

export function RootPageHeader() {
  const navigate = useNavigate();

  const [signedInUserProfile, setSignedInUserProfile] =
    useState<IUserProfile>();

  useEffect(() => {
    fetchUserProfile(AuthenticationService.user.userId).then(
      setSignedInUserProfile
    );
  }, []);

  return (
    <div className="flex w-full flex-row items-center justify-between">
      <button type="button" title="PM Office" onClick={() => navigate('/')}>
        <span className="flex flex-row items-center">
          <PMLogo className="h-[48px] pointer-events-none" />
          <h1 className="hidden sm:block ml-xs text-ellipsis overflow-hidden whitespace-nowrap">
            Office
          </h1>
        </span>
      </button>
      <div className="flex flex-row items-center gap-xs">
        <PIIBadge />
        <PowerUserActiveFeatures />
        <SearchButton />
        <NotificationsCenter />
        <Menu as="div" className="relative">
          {({ open }) => (
            <>
              <Menu.Button className="hover:brightness-125 rounded-full transition-all relative flex items-center justify-center">
                {_.isUndefined(signedInUserProfile) ? (
                  <UserAvatarSkeleton />
                ) : (
                  <UserAvatar
                    userName={signedInUserProfile.name}
                    userType={
                      signedInUserProfile.isDiscountCustomer
                        ? 'discount-customer'
                        : signedInUserProfile.isTeampartner
                        ? 'teampartner'
                        : 'customer'
                    }
                  />
                )}
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="absolute top-full right-1 w-[200px] z-10">
                  {open && (
                    <Menu.Items
                      static
                      className="p-sm bg-white shadow-xl rounded-md mt-xxs"
                    >
                      {!_.isUndefined(signedInUserProfile) && (
                        <Menu.Item>
                          <Link to="/account">
                            <UserHeader
                              user={signedInUserProfile}
                              showBadges={false}
                              showQualification={false}
                              showCheckAssurance={false}
                              disableNavigation
                              showCountryFlag
                            />
                          </Link>
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        <div className="mt-sm m-auto w-full">
                          <SignoutButton />
                        </div>
                      </Menu.Item>
                    </Menu.Items>
                  )}
                </div>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
}

import { IProspectCreationProps } from 'types';

import { execPost } from '../RequestUtils';

export async function createProspect(
  prospect: IProspectCreationProps
): Promise<string> {
  const { sponsorId, ...requestBody } = prospect;
  const searchParams = new URLSearchParams();
  searchParams.set('sponsor', sponsorId);
  searchParams.set('countryCode', prospect.address?.country ?? '');

  const { id } = await execPost<{ readonly id: string }>(
    `${
      process.env.REACT_APP_USER_MANAGEMENT_API
    }/prospects/customer/start?${searchParams.toString()}`,
    requestBody
  );

  return id;
}

import { QualificationBadge } from 'components/badges';
import { UnorderedList } from 'components/lists';
import { ProgressWidget } from 'components/progress/progress-loop/ProgressWidget';
import { Disclaimer, FormattedUnitValue, LabelledText } from 'components/text';
import { QUALIFICATIONS, QualificationLevel } from 'contants';
import { replaceStringWithComponent } from 'libs/components';
import { Icons } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { Fragment } from 'react';
import { IUserQualificationData } from 'types';

import { QualificationHistory } from './QualificationHistory';
import { useProgressTypeI18n } from '../../hooks';
import {
  CurrentQualificationHeader,
  NextQualificationHeader
} from '../firstline/headers/QualificationHeaders';

interface IWidgetQualificationDetailsProps extends IUserQualificationData {
  readonly userId: string;
  readonly currentQualificationLevel?: QualificationLevel;
  readonly canReachCheckAssurance: boolean;
}

export function WidgetQualificationDetails(
  props: IWidgetQualificationDetailsProps
) {
  const {
    nextQualificationLevel,
    nextQualificationProgress,
    qualificationHistory,
    currentQualificationLevel
  } = props;
  const { t } = useTranslation();
  const progressTypeT = useProgressTypeI18n();

  return (
    <section>
      <div className="flex flex-col items-center">
        <ProgressWidget percentage={nextQualificationProgress.percent} />
        {!_.isUndefined(nextQualificationLevel) && (
          <h2
            className={`font-bold ${
              nextQualificationProgress.percent === 100
                ? 'text-success'
                : 'text-primary'
            }`}
          >
            {replaceStringWithComponent(t('to {{level}}'), '{{level}}', () => (
              <QualificationBadge qualification={nextQualificationLevel} />
            )).map((elem, index) => (
              <Fragment key={`to_level__${index}`}>{elem}</Fragment>
            ))}
          </h2>
        )}
      </div>
      <div className="mt-md">
        <LabelledText
          label={<CurrentQualificationHeader />}
          value={
            <>
              {_.isUndefined(currentQualificationLevel) &&
                props.canReachCheckAssurance && (
                  <Disclaimer
                    message={t('Check Assurance is needed to qualify.')}
                  />
                )}
              {!_.isUndefined(currentQualificationLevel) && (
                <span className="w-fit flex items-center">
                  <QualificationBadge
                    qualification={currentQualificationLevel}
                    currentMonth
                  />
                  <span className="ml-xxs">
                    {QUALIFICATIONS[currentQualificationLevel].name}
                  </span>
                </span>
              )}
            </>
          }
        />
      </div>
      {!_.isUndefined(nextQualificationLevel) && (
        <div className="mt-md">
          <LabelledText
            label={<NextQualificationHeader />}
            value={
              <span className="w-fit flex items-center">
                <QualificationBadge qualification={nextQualificationLevel} />
                <span className="ml-xxs">
                  {QUALIFICATIONS[nextQualificationLevel].name}
                </span>
              </span>
            }
          />
        </div>
      )}
      <div className="bg-body -mx-sm my-sm p-sm">
        <UnorderedList
          items={nextQualificationProgress.details.map(pd => ({
            leftNode:
              pd.value >= pd.target ? (
                <Icons.Common.Success size={16} className="text-success" />
              ) : (
                <Icons.Common.Cancel size={16} className="text-error" />
              ),
            content: progressTypeT(pd.type),
            rightNode: (
              <span className="flex flex-row text-right whitespace-nowrap">
                <FormattedUnitValue value={pd.value} unit={pd.unit} />
                /
                <FormattedUnitValue value={pd.target} unit={pd.unit} />
              </span>
            )
          }))}
        />
      </div>
      <div className="mt-md">
        <QualificationHistory history={qualificationHistory} />
      </div>
    </section>
  );
}

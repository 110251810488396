import { Month } from 'libs/time';
import _ from 'lodash';
import { PaymentInstrumentType, PaymentModule, IPaymentIntrument } from 'types';
import { padWithZeros } from 'utils';

export function parsePaymentInstrumentMaskedInfo(
  paymentModule: PaymentModule,
  maskedInfo: string | undefined
): IPaymentIntrument {
  let info = maskedInfo;
  let expirationDate: string | undefined;
  let instrumentBrand: string | undefined;

  if (
    !_.isUndefined(maskedInfo) &&
    (isCard(paymentModule) || isGooglePay(paymentModule))
  ) {
    // Cards and GooglePay card instruments contain information about the
    // card type and it's expiration date. The information is space separated
    // but we need to keep in mind that some card types can have spaces too
    // (e.g., "American Express").
    // What we can be sure is the order in the string:
    //  "<card-type> <masked-card-number> <expiration-date>"
    const DELIMITER = ' ';
    const expirationDateDelimiterIndex = maskedInfo.lastIndexOf(DELIMITER);
    expirationDate = maskedInfo.substring(expirationDateDelimiterIndex + 1);
    const infoDelimiterIndex = maskedInfo
      .substring(0, expirationDateDelimiterIndex)
      .lastIndexOf(DELIMITER);
    info = maskedInfo.substring(
      infoDelimiterIndex + 1,
      expirationDateDelimiterIndex
    );
    instrumentBrand = maskedInfo.substring(0, infoDelimiterIndex).toLowerCase();

    // Ensure we have always info in case of unexpected string format
    if (!info) {
      info = maskedInfo;
    }
  }

  return {
    methodType: paymentModule,
    info: formatInstrumentInfo(paymentModule, info),
    expirationDate: formatInstrumentExpirationDate(expirationDate),
    instrumentBrand: getInstrumentBrand(paymentModule, instrumentBrand)
  };
}

function formatInstrumentInfo(
  paymentModule: PaymentModule,
  instrumentMaskedInfo?: string
): string {
  if (isCard(paymentModule) || isGooglePay(paymentModule)) {
    if (instrumentMaskedInfo && instrumentMaskedInfo.length > 4) {
      // Card can contain 4 or more digits. For the sake of consistency, we
      // only show the last 4 digits and normalize the way we mask the other.
      // E.g., "1234", "****1234", "1234********1234" are all valid strings.
      return `**** ${instrumentMaskedInfo.slice(-4)}`;
    }

    return '••••';
  }

  if (isPayPal(paymentModule) && instrumentMaskedInfo) {
    // Replace myemail@somewhere.com with m*****l@s***.com
    const [beforeAtSymbol, afterAtSymbol] = instrumentMaskedInfo.split('@');
    return `${beforeAtSymbol.at(0)}${Array(
      Math.max(0, beforeAtSymbol.length - 2)
    )
      .fill('*')
      .join('')}${beforeAtSymbol.at(
      beforeAtSymbol.length - 1
    )}@${afterAtSymbol.at(0)}${Array(
      Math.max(
        0,
        afterAtSymbol.substring(afterAtSymbol?.lastIndexOf('.')).length
      )
    )
      .fill('*')
      .join('')}.${afterAtSymbol.substring(
      afterAtSymbol?.lastIndexOf('.') + 1
    )}`;
  }

  if (isDirectDebit(paymentModule) || isSEPA(paymentModule)) {
    if (instrumentMaskedInfo && instrumentMaskedInfo.length >= 4) {
      // Direct debit instruments only contain partial IBAN number where only the
      // last 4 digits are available. The rest is masked. E.g., "xxxx1234"
      return instrumentMaskedInfo.slice(-4);
    }

    if (isSEPA(paymentModule)) {
      return 'SEPA';
    }

    if (isDirectDebit(paymentModule)) {
      return 'LSV';
    }
  }

  if (isSubsidiary(paymentModule)) {
    return 'PM SUBSIDIARY';
  }

  if (isECommerceConnect(paymentModule)) {
    return 'ECommerce Connect';
  }

  if (isNuveiTwint(paymentModule)) {
    return 'Twint';
  }

  if (is2C2P(paymentModule)) {
    return '2C2P';
  }

  return instrumentMaskedInfo ?? '';
}

function formatInstrumentExpirationDate(
  expirationDate?: string
): Month | undefined {
  if (!expirationDate) {
    return undefined;
  }

  const [expirationYear, expirationMonth] = expirationDate
    ? expirationDate.split('-')
    : [];

  // Instruments are valid until the end of the last day of the month in the
  // expiration date provided. E.g., an instruments with expiration date of
  // expiration 2022-03 will be valid until the March 31st 23h 59m 59s 999ms.
  //
  // This is the reason why we don't need to fix the month value here to be
  // within the interval [0, 11]. Preserving the original value corresponding
  // to "internval + 1", we are essentialy setting the 1st day of the next
  // month. E.g., for the same expiration date of 2022-03, the date object
  // with month value of 3 will represent April 1st 0h 0m 0s 0ms. This date
  // is the exact moment at which the card is considered expired.
  return Month.create(
    `${expirationYear}-${padWithZeros(expirationMonth, 2)}-01`
  );
}

function getInstrumentBrand(
  paymentModule: PaymentModule,
  brandName?: string
): ReadonlyArray<PaymentInstrumentType> {
  if (isPayPal(paymentModule)) {
    return [PaymentInstrumentType.PayPal];
  }

  if (isDirectDebit(paymentModule)) {
    return [PaymentInstrumentType.DirectDebit];
  }

  if (isSEPA(paymentModule)) {
    return [PaymentInstrumentType.SEPA];
  }

  if (isApplePay(paymentModule)) {
    return [PaymentInstrumentType.ApplePay];
  }

  if (isBancontact(paymentModule)) {
    return [PaymentInstrumentType.Bancontact];
  }

  if (isTransbank(paymentModule)) {
    return [PaymentInstrumentType.Transbank];
  }

  if (isIDeal(paymentModule)) {
    return [PaymentInstrumentType.iDeal];
  }

  if (isSubsidiary(paymentModule)) {
    return [PaymentInstrumentType.PMSubsidiary];
  }

  if (isNuveiTwint(paymentModule)) {
    return [PaymentInstrumentType.NuveiTwint];
  }

  if (isECommerceConnect(paymentModule)) {
    return [PaymentInstrumentType.ECommerceConnect];
  }

  if (is2C2P(paymentModule)) {
    return [PaymentInstrumentType.TwoCTwoP];
  }

  if (isCard(paymentModule) || isGooglePay(paymentModule)) {
    const brand = brandName
      ? mapBrandNameToInstrumentBrand(brandName)
      : undefined;

    if (isGooglePay(paymentModule) && brand) {
      return [PaymentInstrumentType.GooglePay, brand];
    }

    if (brand) {
      return [brand];
    }
  }

  return [PaymentInstrumentType.GenericPayment];
}

function mapBrandNameToInstrumentBrand(
  brandName: string
): PaymentInstrumentType | undefined {
  switch (brandName) {
    case 'amex':
    case 'american-express':
    case 'american express':
      return PaymentInstrumentType.Amex;
    case 'diners':
    case 'diners-club':
    case 'diners club':
      return PaymentInstrumentType.Diners;
    case 'discover':
      return PaymentInstrumentType.Discover;
    case 'elo':
      return PaymentInstrumentType.Elo;
    case 'jcb':
      return PaymentInstrumentType.Jcb;
    case 'mastercard':
    case 'master-card':
    case 'master_card':
    case 'ecmc':
      return PaymentInstrumentType.MasterCard;
    case 'maestro':
    case 'uk_maestro':
      return PaymentInstrumentType.Maestro;
    case 'unionpay':
    case 'union_pay':
    case 'china_union_pay':
      return PaymentInstrumentType.UnionPay;
    case 'visa':
      return PaymentInstrumentType.Visa;
    default:
      return undefined;
  }
}

function isCard(module: PaymentModule): boolean {
  return (
    module === PaymentModule.BraintreeCard ||
    module === PaymentModule.WorldpayCard ||
    module === PaymentModule.FlutterwaveCreditCard ||
    module === PaymentModule.NuveiCreditCard
  );
}

function isPayPal(module: PaymentModule): boolean {
  return module === PaymentModule.BraintreePayPal;
}

function isDirectDebit(module: PaymentModule): boolean {
  return module === PaymentModule.DirectDebitLSV;
}

function isSEPA(module: PaymentModule): boolean {
  return (
    module === PaymentModule.Sepa || module === PaymentModule.BraintreeSepa
  );
}

function isGooglePay(module: PaymentModule): boolean {
  return module === PaymentModule.BraintreeGooglePay;
}

function isApplePay(module: PaymentModule): boolean {
  return module === PaymentModule.BraintreeApplePay;
}

function isBancontact(module: PaymentModule): boolean {
  return module === PaymentModule.WorldpayBancontact;
}

function isTransbank(module: PaymentModule): boolean {
  return module === PaymentModule.Transbank;
}

function isIDeal(module: PaymentModule): boolean {
  return module === PaymentModule.WorldpayIdeal;
}

function isSubsidiary(module: PaymentModule): boolean {
  return module === PaymentModule.PaymentAtSubsidiary;
}

function isNuveiTwint(module: PaymentModule): boolean {
  return module === PaymentModule.NuveiTwint;
}

function isECommerceConnect(module: PaymentModule): boolean {
  return module === PaymentModule.ECommerceConnect;
}

function is2C2P(module: PaymentModule): boolean {
  return module === PaymentModule.TwoCTwoP;
}

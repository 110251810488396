import { IPopCard } from 'components/popcards/IPopCard';
import { usePopCardContext } from 'components/popcards/PopCardContext';
import { ProgressWidget } from 'components/progress/progress-loop/ProgressWidget';
import { useGlobalConfigsContext } from 'contexts';
import { usePatchOfficeServerState } from 'hooks';
import { IconName, Icon, FeatureIconName, Icons } from 'libs/icons';
import { Month, Timestamp } from 'libs/time';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import AuthenticationService from 'services/AuthenticationService';
import { IProgress } from 'types';

import { CheckAssuranceStatusMessage } from './CheckAssuranceStatusMessage';
import { WidgetCheckAssuranceDetails } from './WidgetCheckAssuranceDetails';
import { Widget } from '../Widget';

interface IWidgetCheckAssuranceProps {
  readonly userId: string;
  readonly period: Month;
  readonly percent: number;
  readonly options: ReadonlyArray<IProgress>;
}

export function WidgetCheckAssurance(props: IWidgetCheckAssuranceProps) {
  const { t } = useTranslation();
  const { queuePopCard } = usePopCardContext();
  const {
    app: {
      office: { lastCheckAssuranceAchivementDate }
    }
  } = useGlobalConfigsContext();
  const { patchPreferences } = usePatchOfficeServerState();

  const [showSuccessPage, setShowSuccessPage] = useState<boolean>(false);

  useEffect(() => {
    if (
      AuthenticationService.user.userId === props.userId &&
      props.percent === 100 &&
      _.isUndefined(lastCheckAssuranceAchivementDate) &&
      props.period.isTodaysMonth()
    ) {
      _.delay(setShowSuccessPage, 1000, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showSuccessPage) {
      const caAchievementMessage: IPopCard = {
        title: t('Congratulations!'),
        description: t("You've reached your Check Assurance."),
        id: 'pmi.web.office-popcard--ca-success',
        fireworks: 'green',
        cta: t('Close'),
        ctaAction: () => {
          setShowSuccessPage(false);
          patchPreferences({
            lastCheckAssuranceAchivementDate: Timestamp.now()
          });
        },
        imgUri: '/imgs/promo/CheckAssuranceAchievement.svg'
      };

      queuePopCard(caAchievementMessage);
    }
  }, [patchPreferences, queuePopCard, showSuccessPage, t]);

  return (
    <Widget
      traceId="CheckAssurance"
      title={t('Check Assurance')}
      detailedViewButtonLabel={t('Details')}
      iconName={FeatureIconName.CheckAssurance}
      detailedView={
        props.options.length > 0 &&
        props.options[0].details.length > 0 && (
          <WidgetCheckAssuranceDetails {...props} />
        )
      }
      content={
        <div className="flex flex-row items-center justify-center sm:justify-evenly">
          <div className="flex flex-col items-center">
            <ProgressWidget percentage={props.percent} />
            {props.percent === 100 ? (
              <Icon name={IconName.Shield} size={24} className="text-success" />
            ) : (
              <Icons.Common.Cancel size={24} className="text-error" />
            )}
          </div>
          <div className="space-y-xs w-1/2 hidden sm:block">
            {props.percent === 100 ? (
              <p>{t('Achieved the Check Assurance!')}</p>
            ) : (
              <CheckAssuranceStatusMessage {...props} />
            )}
          </div>
        </div>
      }
    />
  );
}

import { PageTitle } from 'components/layouts/page-title/PageTitle';
import { Pagination } from 'components/pagination';
import { LoadingSpinner } from 'components/spinners';
import { Disclaimer } from 'components/text';
import { useScreenEvents } from 'contexts';
import { AccountBackButton } from 'features/account';
import { useOrders } from 'features/account/orders';
import { OrdersList } from 'features/account/orders/OrdersList';
import { Icons } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useOutlet, useParams } from 'react-router-dom';

const DEFAULT_ORDER_LIST_PAGE_SIZE = 20;

export function AccountOrdersRoute() {
  const { t } = useTranslation();
  const outlet = useOutlet();
  const { lg } = useScreenEvents();
  const { orderId } = useParams();
  const navigate = useNavigate();

  const [pageDetails, setPageDetails] = useState<{
    readonly page: number;
    readonly perPage: number;
  }>({
    page: 1,
    perPage: DEFAULT_ORDER_LIST_PAGE_SIZE
  });

  const {
    data: paginatedOrders,
    error,
    isLoading
  } = useOrders(pageDetails.page, pageDetails.perPage);

  useEffect(() => {
    if (
      lg &&
      !_.isUndefined(paginatedOrders) &&
      paginatedOrders.results.length > 0 &&
      _.isUndefined(orderId)
    ) {
      navigate(`/account/orders/${paginatedOrders.results[0].id}`);
    }
  }, [lg, navigate, orderId, paginatedOrders]);

  if (!_.isUndefined(paginatedOrders) && paginatedOrders.results.length === 0) {
    return (
      <>
        <div className="py-xl px-md flex flex-row items-center justify-center gap-sm flex-wrap rounded-md bg-lightGray">
          <p className="text-center">
            {t("Currently, you don't have any orders.")}
          </p>
        </div>
      </>
    );
  }

  return (
    <>
      <PageTitle />
      <section className="space-y-md m-auto">
        <div className={`${outlet && 'hidden lg:block'}`}>
          <AccountBackButton />
        </div>
        <div
          className={
            outlet
              ? 'grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-sm'
              : ''
          }
        >
          <div className={`${outlet ? 'hidden lg:block' : ''}`}>
            {!_.isUndefined(error) && (
              <Disclaimer
                message={t(
                  'Something went wrong. If the problem persists, please contact our support team.'
                )}
                type="error"
              />
            )}

            {!_.isUndefined(pageDetails) &&
              !_.isUndefined(paginatedOrders) &&
              paginatedOrders.paginationInfo.totalPages > 1 && (
                <div className="flex justify-end mb-xs">
                  <Pagination
                    currentPage={pageDetails.page}
                    onPageChange={p =>
                      setPageDetails({
                        page: p,
                        perPage: DEFAULT_ORDER_LIST_PAGE_SIZE
                      })
                    }
                    totalPages={paginatedOrders?.paginationInfo.totalPages ?? 0}
                    siblingCount={1}
                  />
                </div>
              )}

            {isLoading && (
              <div className="w-full h-full flex items-center justify-center min-h-[200px]">
                <LoadingSpinner smallSize />
              </div>
            )}

            {paginatedOrders?.results && (
              <div className="bg-white h-fit shadow-md rounded-md p-sm min-w-[320px]">
                <OrdersList orders={paginatedOrders.results} />
              </div>
            )}
          </div>

          <div className="col-span-1 xl:col-span-2">
            {outlet && (
              <NavLink
                to={'/account/orders'}
                className="lg:hidden flex flex-row items-center gap-xs w-fit mb-sm text-primary"
              >
                <Icons.Common.ChevronLeft />
                <span>{t('My Orders')}</span>
              </NavLink>
            )}
            {outlet}
          </div>
        </div>
      </section>
    </>
  );
}

import { ApiError } from 'errors';
import { Timestamp } from 'libs/time';
import _ from 'lodash';

import { getCommonAuthenticatedHeaders } from './HeaderUtils';

const ExecGetCacheMaxRecords = 20;
const ExecGetCacheMaxTimeMS = 90000; // 15 minutes

interface IExecGetCachRecord {
  data: unknown;
  timestamp: number;
}
const ExecGetCache: Record<string, IExecGetCachRecord> = {};
let ExecGetCacheKeyOrder: string[] = [];

/**
 * @deprecated
 */
async function fetchAndCache(
  input: RequestInfo | URL,
  init?: RequestInit | undefined
) {
  const cacheKey = `url:${input}|method:${
    init?.method ?? ''
  }|headers:${JSON.stringify({ ...(init?.headers ?? {}), Authorization: '' })}`;

  if (
    !_.isUndefined(ExecGetCache[cacheKey]) &&
    ExecGetCache[cacheKey].timestamp + ExecGetCacheMaxTimeMS >=
      Timestamp.now().getTime()
  ) {
    return Promise.resolve(ExecGetCache[cacheKey].data);
  }

  const response = handleFetchResponse(await fetch(input, init));

  ExecGetCache[cacheKey] = {
    data: response,
    timestamp: Timestamp.now().getTime()
  };
  ExecGetCacheKeyOrder.push(cacheKey);

  if (ExecGetCacheKeyOrder.length > ExecGetCacheMaxRecords) {
    ExecGetCacheKeyOrder = _.drop(ExecGetCacheKeyOrder, 1);
    delete ExecGetCache[cacheKey];
  }

  return response;
}

export async function execGet<T>(
  apiEndpoint: string,
  options?: {
    contentTypeHeader?: 'text/plain' | 'application/json';
    /**
     * @deprecated
     */
    cache?: boolean;
  }
): Promise<T> {
  const contentTypeHeader = options?.contentTypeHeader ?? 'application/json';

  if (options?.cache) {
    return await fetchAndCache(apiEndpoint, {
      method: 'GET',
      headers: getCommonAuthenticatedHeaders(contentTypeHeader)
    });
  }

  const response = await fetch(apiEndpoint, {
    method: 'GET',
    headers: getCommonAuthenticatedHeaders(contentTypeHeader)
  });

  return handleFetchResponse(response);
}

export async function execPost<T>(
  apiEndpoint: string,
  body: object | string
): Promise<T> {
  const response = await fetch(apiEndpoint, {
    method: 'POST',
    headers: getCommonAuthenticatedHeaders(
      typeof body === 'string' ? 'text/plain' : 'application/json'
    ),
    body: JSON.stringify(body)
  });

  return handleFetchResponse(response);
}

export async function execDelete<T>(apiEndpoint: string): Promise<T> {
  const response = await fetch(apiEndpoint, {
    method: 'DELETE',
    headers: getCommonAuthenticatedHeaders()
  });

  return handleFetchResponse(response);
}

export async function execPut<T>(
  apiEndpoint: string,
  body?: object | string
): Promise<T> {
  const response = await fetch(apiEndpoint, {
    method: 'PUT',
    headers: getCommonAuthenticatedHeaders(
      typeof body === 'string' ? 'text/plain' : 'application/json'
    ),
    body: JSON.stringify(body || {})
  });

  return handleFetchResponse(response);
}

export async function execPatch<T>(
  apiEndpoint: string,
  body: object | string
): Promise<T> {
  const response = await fetch(apiEndpoint, {
    method: 'PATCH',
    headers: getCommonAuthenticatedHeaders(
      typeof body === 'string' ? 'text/plain' : 'application/json'
    ),
    body: JSON.stringify(body)
  });

  return handleFetchResponse(response);
}

async function handleFetchResponse(response: Response) {
  if (response.status === 204 && response.ok) {
    return;
  }

  const textResponse = await response.text();
  let jsonResponse;

  try {
    jsonResponse = await JSON.parse(textResponse);
  } catch (e) {
    //
  }

  if (response.ok) {
    return jsonResponse ?? textResponse;
  }

  if (Array.isArray(jsonResponse)) {
    const errMsg: string = jsonResponse
      .reduce((msg, err) => `${msg ?? ''}\n${err?.detail ?? ''}\n` ?? '\n', '')
      .slice(1)
      .trim();

    throw new ApiError(errMsg, response);
  }

  if (_.isObjectLike(jsonResponse)) {
    const message = _.get(jsonResponse, 'detail', '');
    const traceId = _.get(jsonResponse, 'traceId', undefined);
    const errors = _.get(jsonResponse, 'Errors', undefined);

    throw new ApiError(message, response, traceId, errors);
  }

  throw new ApiError('Unexpected response', response);
}

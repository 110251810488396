import { useGlobalConfigsContext } from 'contexts';
import { useBasketList } from 'features/shared-baskets';
import { Timestamp } from 'libs/time';
import { useTranslation } from 'libs/translations';
import { useEffect } from 'react';
import { fetchUser } from 'services';
import { BasketStatus, IUser } from 'types';

import { INotification, NotificationType } from '../schemas/INotificationItem';
import NotificationsService from '../services/NotificationsService';

export function GenerateFakeSoldBasketBuyerNotifications() {
  const { promoShop } = useGlobalConfigsContext();

  if (promoShop && promoShop.id) {
    return <GenerateFakeNotifications />;
  }

  return null;
}

function GenerateFakeNotifications() {
  const { t, activeLanguage } = useTranslation();
  const {
    app: {
      office: { notificationsSeen }
    }
  } = useGlobalConfigsContext();
  const { data } = useBasketList(1, 99999, activeLanguage);

  useEffect(() => {
    const processNotifications = async () => {
      const persistedNotifications = notificationsSeen;

      if (data) {
        const customerSoldBaskets = data.baskets
          .filter(
            basket => basket.basket.status === BasketStatus.PaymentAuthorized
          )
          .filter(
            basket =>
              !(persistedNotifications ?? []).includes(basket.basket.basketId)
          )
          .reduce((acc, basket) => {
            return acc.set(basket.basket.customerId, [
              ...(acc.get(basket.basket.customerId) ?? []),
              basket.basket.basketId
            ]);
          }, new Map<string, string[]>());

        const userDataPromises: Promise<IUser>[] = [];
        customerSoldBaskets.forEach((values, key) =>
          userDataPromises.push(fetchUser(key))
        );

        Promise.allSettled(userDataPromises).then(results => {
          return results.filter(result => {
            if (
              result.status === 'fulfilled' &&
              result.value.type.toLowerCase() === 'customer'
            ) {
              const basketIds =
                customerSoldBaskets.get(result.value.id) ??
                customerSoldBaskets.get(result.value.publicId ?? '') ??
                [];

              basketIds.forEach(basketId => {
                const notification: INotification = {
                  type: NotificationType.PopCard,
                  popcardFireworks: true,
                  timestamp: Timestamp.now(),
                  id: basketId,
                  title: t(
                    'Great news! {{firstName}} {{lastName}} has just purchased one of the Shared baskets in your Virtual Warehouse!',
                    {
                      firstName: result.value.givenName,
                      lastName: result.value.familyName
                    }
                  ),
                  description: t(
                    "This not only signifies a successful transaction but also contributes significantly to the progress you're making in fulfilling the commitments you've made. Your thoughtful selection and strategic planning are paying off, bringing you closer to your goals. Keep up the excellent work in managing your Virtual Warehouse and watching your commitments turn into achievements!"
                  ),
                  ctaTitle: t('Go to Basket'),
                  internalHref: `/sales/baskets/${basketId}`,
                  thumbnail: '/imgs/vw/vw_basket_customer.jpeg'
                };

                NotificationsService.addNotification(notification);
              });
            }
          });
        });
      }
    };

    processNotifications();
  }, [data, notificationsSeen, t]);

  return null;
}

import { RankBadgeDescription, Badge } from 'components/badges';
import { QualificationLevel } from 'contants';
import { IconName } from 'libs/icons';

interface IRankBadgeProps {
  readonly rank: QualificationLevel;
  readonly hideIcon?: boolean;
  readonly hideDetails?: boolean;
}

export function RankBadge(props: IRankBadgeProps) {
  const badgeComponent = (
    <Badge
      icon={props.hideIcon ? undefined : IconName.HighPriority}
      title={props.rank}
      backgroundColorClass={undefined}
      textColorClass={undefined}
      tooltipDetailsContent={<RankBadgeDescription />}
      hideDetails={props.hideDetails}
    />
  );

  return <>{badgeComponent}</>;
}

interface IPMLogoProps {
  readonly showClaim?: boolean;
  readonly className?: string;
}

export function PMLogo(props: IPMLogoProps) {
  const classNameValue = props.className ?? 'h-14 sm:h-18 md:h-20 flex-none';
  return props.showClaim ? (
    <object
      title="PM International. Simple. Successfull"
      className={classNameValue}
      type="image/svg+xml"
      data="/imgs/pm-logo/PMLogoWithClaim.svg"
    ></object>
  ) : (
    <object
      title="PM International. Simple. Successfull"
      className={classNameValue}
      type="image/svg+xml"
      data="/imgs/pm-logo/PMLogo.svg"
    ></object>
  );
}

import { CountryFlag } from '@pmi.web/react-country-flags';
import { UserHeader } from 'components/headers';
import { UserAvatar } from 'components/icons';
import { LoadingSpinner } from 'components/spinners';
import { PII } from 'features/pii';
import { useUser } from 'hooks';
import { Icons } from 'libs/icons';
import { Timestamp } from 'libs/time';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';

export function BasketOwner(props: {
  readonly basketOwnerId: string;
  readonly basketCreadtedOn: Timestamp;
}) {
  const { t } = useTranslation();
  const { data: userData, isLoading, error } = useUser(props.basketOwnerId);
  const wasProspect =
    (userData?.createdAt.getTime() ?? 0) > props.basketCreadtedOn.getTime() &&
    !userData?.isActive;

  const renderAsProspect =
    !_.isUndefined(userData) && (userData.type === 'Prospect' || wasProspect);

  return (
    <>
      {isLoading && (
        <div className="flex flex-row items-center p-xs justify-start min-h-[52px]">
          <LoadingSpinner tinySize />
        </div>
      )}
      {!_.isUndefined(error) && (
        <div className="flex flex-row gap-xs items-center text-error">
          <Icons.Common.Person
            size={16}
            className="rounded-full bg-errorBg w-avatar-sm h-avatar-sm p-1.5"
          />
          <p className="overflow-hidden text-ellipsis pmi-caption">
            {t('Could not find the owner.')}
          </p>
        </div>
      )}
      {renderAsProspect && (
        <div className="flex flex-row items-center gap-xs">
          <UserAvatar userType="prospect" userName="" />
          <div className="flex flex-col overflow-hidden text-ellipsis">
            <PII type="email" value={userData.emails.at(0)?.email} />
            {userData.addresses[0].country && (
              <CountryFlag countryCode={userData.addresses[0].country} />
            )}
          </div>
        </div>
      )}
      {!_.isUndefined(userData) &&
        userData.type !== 'Prospect' &&
        !wasProspect && (
          <div className="flex flex-row gap-xs border border-transparent w-fit h-fit items-center">
            <UserHeader
              showBadges
              showCheckAssurance
              showCountryFlag
              showQualification
              user={userData}
              disableNavigation
            />
          </div>
        )}
    </>
  );
}

import { Timestamp } from 'libs/time';
import { padWithZeros } from 'utils';

export function isBonusPayoutSelectionDateValid(
  lastBonusPayoutUpdateDate: Timestamp
) {
  const today = Timestamp.now();

  const fifthOfThisMonth = Timestamp.createOrThrow(
    `${today.year}-${padWithZeros(today.month, 2)}-05`
  );
  const fifteenthOfThisMonth = Timestamp.createOrThrow(
    `${today.year}-${padWithZeros(today.month, 2)}-15`
  );

  return lastBonusPayoutUpdateDate.isBetween(
    fifthOfThisMonth,
    fifteenthOfThisMonth
  );
}

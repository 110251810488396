import { InvoiceProductList } from 'features/invoices';
import _ from 'lodash';
import { IBasket } from 'types';

interface IBasketProductsProps extends IBasket {
  readonly showPoints: boolean;
}

export function BasketProducts({
  showPoints,
  ...basketProps
}: IBasketProductsProps) {
  return (
    <InvoiceProductList
      showPoints={showPoints}
      items={basketProps.basket.items.map(i => {
        const imgSrc = i.details.media.find(
          m => m.type === 'image' && !_.isUndefined(m.url)
        );

        return {
          articleNumber: i.articleNumber,
          currencyCode: i.details.currencyCode,
          itemPrice: i.details.displayPrice,
          points: i.details.points,
          quantity: i.quantity,
          name: i.details.attributes.name?.value,
          imgSrc: imgSrc?.url
        };
      })}
    />
  );
}

import { computePeriodOptions } from 'components/period-selection';
import { QUALIFICATIONS } from 'contants';
import _ from 'lodash';
import { TUserSearchSorting } from 'services';
import { QualificationLevel } from 'types';

import { useUrlSearchParams } from './useUrlSearchParams';
import { IFilterFormValues } from '../types/IFilterFormValues';
import { ISearchFormValues } from '../types/ISearchFormValues';
import { ISortFormValues } from '../types/ISortFormValues';

const DEFAULT_FILTERS: IFilterFormValues = {
  byCountry: '',
  period: computePeriodOptions()[0].toString(),
  byQualificationFrom: undefined,
  byQualificationTo: QUALIFICATIONS[QualificationLevel.Champion].numericValue,
  withCheckAssurance: '',
  newRegistrations: '',
  withNewQualification: '',
  withSponsorActivity: '',
  fitlineClubMembers: '',
  byStructureVolumeFrom: 0,
  byStructureVolumeTo: Infinity
};

const DEFAULT_SORT_BY: ISortFormValues = {
  sorting: TUserSearchSorting.ByStructureVolumeDesc
};

const DEFAULT_SEARCH: ISearchFormValues = {
  search: ''
};

export function useAnalyticsFilters() {
  const {
    filterParams: urlFilterParams,
    searchParams: urlSearchParams,
    sortbyParams: urlSortParams,
    updateUrlSearchParams
  } = useUrlSearchParams();

  return {
    filterParams: urlFilterParams ?? DEFAULT_FILTERS,
    searchParams: urlSearchParams ?? DEFAULT_SEARCH,
    sortParams: urlSortParams ?? DEFAULT_SORT_BY,
    updateParams: updateUrlSearchParams,
    hasFiltersApplied:
      !!urlFilterParams && !_.isEqual(urlFilterParams, DEFAULT_FILTERS),
    hasSortByApplied:
      !!urlSortParams && !_.isEqual(urlSortParams, DEFAULT_SORT_BY)
  };
}

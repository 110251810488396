// eslint-disable-next-line no-restricted-imports
import { DateTime } from 'luxon';

/**
 * A FixedDate represents a date that is meant to be the same anywhere in the world.
 * E.g., birth dates, registration dates, etc. Dates that don't change with timezones.
 */
export class FixedDate {
  private _date: DateTime;
  private _rawValue: string;

  private constructor(value: string) {
    this._rawValue = value;

    // Remove time information if it exists
    const sanitizedValue = value.split('T')[0];

    this._date = DateTime.fromISO(sanitizedValue);

    // Mark the date as "local" to ensure it will be presented equaly, anywhere in the world.
    this._date = this._date.toLocal();
  }

  static create(value: string): FixedDate | undefined {
    const date = new FixedDate(value);

    if (!date._date.isValid) {
      return undefined;
    }

    return date;
  }

  static createOrThrow(value: string): FixedDate {
    const result = FixedDate.create(value);

    if (result === void 0) {
      throw new Error(`Invalid FixedDate: ${value}`);
    }

    return result;
  }

  public get rawValue() {
    return this._rawValue;
  }

  public get year() {
    return this._date.year;
  }

  public get month() {
    return this._date.month;
  }

  public get day() {
    return this._date.day;
  }

  public getTime(): number {
    return this._date.toMillis();
  }

  public toLocaleString(
    locale: string,
    dateStyle: 'short' | 'medium' | 'long'
  ): string {
    return this._date
      .setLocale(locale)
      .toLocaleString({ dateStyle: dateStyle });
  }
}

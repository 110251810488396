import { PrimaryButton } from '@pmi.web/react-common';
import { ProspectCreationForm } from '@pmi.web/react-user-forms';
import { LoadingSpinner } from 'components/spinners';
import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { useBoolean } from 'hooks';
import { triggerErrorToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import { useEffect, useState } from 'react';
import { createProspect, fetchUser } from 'services';
import AuthenticationService from 'services/AuthenticationService';
import { IProspectCreationProps } from 'types';

import './BasketOwnerSelection.scss';
import { SelectNewProspectCountry } from './SelectNewProspectCountry';

export function SelectNewProspect() {
  const { userId: sponsorId } = AuthenticationService.user;
  const { t, activeLanguage } = useTranslation();

  const {
    dispatchStepEvent,
    basketOwnerId,
    basketOwnerCountry,
    isBasketOwnerProspect,
    shopId
  } = useNewBasketContext();

  const [isLoading, { setFalse: stopLoading, setTrue: startLoading }] =
    useBoolean(false);
  const [isFormValid, { setFalse: setFormInvalid, setTrue: setFormValid }] =
    useBoolean(false);

  const [prospectData, setProspectData] = useState<
    IProspectCreationProps | undefined
  >();

  const onError = (err: Error) => {
    triggerErrorToast(err.message);
  };

  const onSaveProspect = async (
    prospectCreationProps: IProspectCreationProps
  ) => {
    if (!prospectCreationProps || !shopId) {
      return;
    }

    // Save data to state in case something goes wrong
    setProspectData(prospectCreationProps);

    startLoading();
    try {
      const prospectId = await createProspect(prospectCreationProps);

      if (!prospectId) {
        onError(new Error('Prospect was not created successfully'));
        return;
      }

      dispatchStepEvent({
        type: 'set-prospect-id',
        value: prospectId
      });

      dispatchStepEvent({
        type: 'confirm-user-id'
      });
    } catch (err) {
      onError(err as Error);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    if (basketOwnerId && isBasketOwnerProspect && shopId) {
      startLoading();
      fetchUser(basketOwnerId)
        .then(apiData =>
          setProspectData({
            sponsorId: apiData.sponsorId,
            familyName: apiData.familyName,
            givenName: apiData.givenName,
            salutation: apiData.salutation,
            email: apiData.emails[0]?.email,
            address: apiData.addresses[0],
            dateOfBirth: apiData.dateOfBirth?.rawValue,
            phoneNumber: apiData.phoneNumbers[0]?.number
          })
        )
        .finally(() => stopLoading());
    }
  }, [basketOwnerId, isBasketOwnerProspect, shopId, startLoading, stopLoading]);

  if (isLoading) {
    return (
      <div className="w-full flex justify-center p-md">
        <LoadingSpinner smallSize />
      </div>
    );
  }

  return (
    <>
      <div className="px-xs">
        <SelectNewProspectCountry />
      </div>
      {basketOwnerCountry && (
        <div className="px-xs mt-md">
          <ProspectCreationForm
            country={basketOwnerCountry}
            language={activeLanguage}
            formId="prospectform"
            onValidation={isValid =>
              isValid ? setFormValid() : setFormInvalid()
            }
            onSubmit={onSaveProspect}
            defaultValues={prospectData ?? { sponsorId }}
          />
        </div>
      )}
      {isLoading && (
        <div className="p-sm flex items-center justify-center w-full min-h-[100px]">
          <LoadingSpinner smallSize />
        </div>
      )}
      <div className="flex justify-end mt-sm sticky bottom-0 bg-white py-xs bg-opacity-90">
        <PrimaryButton
          disabled={!isFormValid}
          type="submit"
          form="prospectform"
        >
          {t('Continue')}
        </PrimaryButton>
      </div>
    </>
  );
}

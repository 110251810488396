import { Timestamp } from 'libs/time';
import { CommitmentStatus, ICommitment } from 'types';

const EXPIRING_PERIOD_IN_MS = 86400000; /* 24 hours */

export function isCommimentExpiring(commitment: ICommitment): boolean {
  if (
    commitment.status !== CommitmentStatus.Active ||
    isCommitmentExpired(commitment)
  ) {
    return false;
  }

  const timeDiff = commitment.expiresOn.getTime() - Timestamp.now().getTime();

  return timeDiff > 0 && timeDiff < EXPIRING_PERIOD_IN_MS;
}

export function isCommitmentExpired(commitment: ICommitment): boolean {
  const timeDiff = commitment.expiresOn.getTime() - Timestamp.now().getTime();
  return commitment.status === CommitmentStatus.Expired || timeDiff < 0;
}

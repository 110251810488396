import { Timestamp } from 'libs/time';
import _ from 'lodash';

import {
  INotification,
  INotificationObject,
  INotificationSerializedObject
} from '../schemas/INotificationItem';

const NOTIFICATIONS_DATABASE_NAME = 'pmi.web.office-notifications-db';
const NOTIFICATIONS_DATABASE_VERSION = 1;
const NOTIFICATIONS_OBJECT_STORE_NAME = 'notifications';

const SEVEN_DAYS_MS = 604800000;

class NoticationsService {
  private _db: IDBDatabase | undefined;

  private _subscribers: Map<string, () => void>;

  constructor() {
    this._subscribers = new Map();
  }

  public subscribe(subscribeCallback: () => void) {
    const subscriptionId = _.uniqueId();
    this._subscribers.set(subscriptionId, subscribeCallback);

    return {
      unsubscribe: () => {
        this._subscribers.delete(subscriptionId);
      }
    };
  }

  public async getNotifications(): Promise<ReadonlyArray<INotificationObject>> {
    if (_.isUndefined(this._db)) {
      await this.init();
    }

    return new Promise((resolve, reject) => {
      if (_.isUndefined(this._db)) {
        throw new Error('Database not found.');
      }

      const transation = this._db.transaction(
        NOTIFICATIONS_OBJECT_STORE_NAME,
        'readwrite'
      );

      transation.onerror = reject;

      const objectStore = transation.objectStore(
        NOTIFICATIONS_OBJECT_STORE_NAME
      );

      const request = objectStore.getAll();

      request.onsuccess = () =>
        resolve(
          this.deserializeNotificationObjects(request.result ?? []).filter(
            n => {
              return (
                // filter "hidden" notifications
                (n.dateFrom === undefined ||
                  Timestamp.now().getTime() > n.dateFrom.getTime()) &&
                // filter "expired" notifications
                (n.dateTo === undefined ||
                  Timestamp.now().getTime() < n.dateTo.getTime()) &&
                // filter "older than 7 days" notifications
                Timestamp.now().getTime() - SEVEN_DAYS_MS <
                  n.timestamp.getTime()
              );
            }
          )
        );
    });
  }

  public async addNotification(notification: INotification) {
    if (
      notification.timestamp.getTime() <
      Timestamp.now().getTime() - SEVEN_DAYS_MS
    ) {
      console.info('Notification is older than 7 days! Ignored.', notification);
      return Promise.resolve();
    }

    if (
      notification.dateTo &&
      notification.dateTo.getTime() < Timestamp.now().getTime()
    ) {
      console.info(
        'Notification dateTo is in the past! Ignored.',
        notification
      );
      return Promise.resolve();
    }

    if (_.isUndefined(this._db)) {
      await this.init();
    }

    if (_.isUndefined(this._db)) {
      throw new Error('Database is missing.');
    }

    const transation = this._db.transaction(
      NOTIFICATIONS_OBJECT_STORE_NAME,
      'readwrite'
    );

    const objectStore = transation.objectStore(NOTIFICATIONS_OBJECT_STORE_NAME);
    const notificationObj: INotificationSerializedObject = {
      ...notification,
      timestamp: notification.timestamp.getTime(),
      dateFrom: notification.dateFrom?.getTime(),
      dateTo: notification.dateTo?.getTime(),
      read: false
    };

    const request = objectStore.add(notificationObj);

    // We don't care about failures. We are "add"ing to ensure that if it
    // already exists, it won't be added again. This way we don't need to check
    // if exists to decided what the value of the "read" property will be
    transation.onerror = _.noop;
    request.onsuccess = () => this.notifySubscribers();
  }

  public markNotificationAsRead(notificationId: string) {
    if (_.isUndefined(this._db)) {
      throw new Error('Database not found.');
    }

    const transation = this._db.transaction(
      NOTIFICATIONS_OBJECT_STORE_NAME,
      'readwrite'
    );
    transation.onerror = console.error;

    const objectStore = transation.objectStore(NOTIFICATIONS_OBJECT_STORE_NAME);
    const request = objectStore.get(notificationId);

    request.onsuccess = () => {
      objectStore.put({
        ...request.result,
        read: true
      });
    };

    transation.oncomplete = () => {
      this.notifySubscribers();
    };
  }

  private async init() {
    if (_.isUndefined(this._db)) {
      await this.connectToDatabase();
    }

    await this.purge();
  }

  private connectToDatabase() {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(
        NOTIFICATIONS_DATABASE_NAME,
        NOTIFICATIONS_DATABASE_VERSION
      );

      request.onsuccess = event => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this._db = (event?.target as any)?.result as IDBDatabase;
        resolve(void 0);
      };

      request.onerror = event => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        reject((event?.target as any)?.error);
      };

      request.onupgradeneeded = event => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const db = (event.target as any).result as IDBDatabase;
        db.createObjectStore(NOTIFICATIONS_OBJECT_STORE_NAME, {
          keyPath: 'id'
        });
      };
    });
  }

  private async purge() {
    return new Promise((resolve, reject) => {
      if (_.isUndefined(this._db)) {
        throw new Error('Database not found.');
      }

      const transation = this._db.transaction(
        NOTIFICATIONS_OBJECT_STORE_NAME,
        'readwrite'
      );

      transation.onerror = reject;
      transation.oncomplete = resolve;

      const objectStore = transation.objectStore(
        NOTIFICATIONS_OBJECT_STORE_NAME
      );

      const request = objectStore.getAll();

      request.onsuccess = () => {
        const expiredNotifications = this.deserializeNotificationObjects(
          request.result
        )
          .filter(notification => {
            return (
              notification.timestamp.getTime() <
              Timestamp.now().getTime() - SEVEN_DAYS_MS
            );
          })
          .map(expiredNotification => expiredNotification.id);

        objectStore.delete(expiredNotifications);
      };
    });
  }

  private notifySubscribers() {
    this._subscribers.forEach(notify => notify());
  }

  private deserializeNotificationObjects(
    notifications: ReadonlyArray<INotificationSerializedObject>
  ): ReadonlyArray<INotificationObject> {
    return [...notifications]
      .sort((a, b) => {
        return b.timestamp - a.timestamp;
      })
      .map(n => {
        return {
          ...n,
          timestamp: Timestamp.createOrThrow(n.timestamp),
          dateFrom: !_.isUndefined(n.dateFrom)
            ? Timestamp.create(n.dateFrom)
            : undefined,
          dateTo: !_.isUndefined(n.dateTo)
            ? Timestamp.create(n.dateTo)
            : undefined
        };
      });
  }
}

export default new NoticationsService();

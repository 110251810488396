import { Timestamp } from 'libs/time';
import _ from 'lodash';
import {
  CommitmentStatus,
  ICommitment,
  ICommitmentItem,
  ICommitmentList
} from 'types';

import { parsePaymentInstrumentMaskedInfo } from '../PaymentsServiceHelper';
import {
  ICommitmentApi,
  ICommitmentItemApi,
  ICommitmentListApi
} from './types/ICommitmentApi';

export function parseAPICommitmentList(
  apiCommitment: ICommitmentListApi
): ICommitmentList {
  return {
    ...apiCommitment,
    commitments: apiCommitment.results.map(parseAPICommitment)
  };
}

export function parseAPICommitment(apiCommitment: ICommitmentApi): ICommitment {
  return {
    ...apiCommitment,
    status: apiCommitment.status.toLowerCase() as CommitmentStatus,
    items: apiCommitment.items.map(parseCommitmentItem),
    createdOn: Timestamp.createOrThrow(apiCommitment.createdOn),
    expiresOn: Timestamp.createOrThrow(apiCommitment.expiresOn),
    payment: {
      ...apiCommitment.payment,
      instrumentReference: parsePaymentInstrumentMaskedInfo(
        apiCommitment.payment.methodType,
        apiCommitment.payment.maskedInstrumentRef
      )
    },
    billing: apiCommitment.billing
      ? {
          ...apiCommitment.billing,
          address: {
            addressLine1: nonEmptyStringOrUndefined(
              apiCommitment.billing.address.addressLine1
            ),
            addressLine2: nonEmptyStringOrUndefined(
              apiCommitment.billing.address.addressLine2
            ),
            streetPob: nonEmptyStringOrUndefined(
              apiCommitment.billing.address.streetPob
            ),
            countryCode: nonEmptyStringOrUndefined(
              apiCommitment.billing.address.countryCode
            ),
            zipCode: nonEmptyStringOrUndefined(
              apiCommitment.billing.address.zipCode
            ),
            city: nonEmptyStringOrUndefined(apiCommitment.billing.address.city),
            district: nonEmptyStringOrUndefined(
              apiCommitment.billing.address.district
            ),
            federalState: nonEmptyStringOrUndefined(
              apiCommitment.billing.address.federalState
            ),
            county: nonEmptyStringOrUndefined(
              apiCommitment.billing.address.county
            ),
            email: nonEmptyStringOrUndefined(
              apiCommitment.billing.address.email
            ),
            phone1: nonEmptyStringOrUndefined(
              apiCommitment.billing.address.phone1
            ),
            phone2: nonEmptyStringOrUndefined(
              apiCommitment.billing.address.phone2
            ),
            mobile1: nonEmptyStringOrUndefined(
              apiCommitment.billing.address.mobile1
            ),
            mobile2: nonEmptyStringOrUndefined(
              apiCommitment.billing.address.mobile2
            )
          }
        }
      : undefined,
    delivery: apiCommitment.delivery
      ? {
          ...apiCommitment.delivery,
          address: {
            addressLine1: nonEmptyStringOrUndefined(
              apiCommitment.delivery.address.addressLine1
            ),
            addressLine2: nonEmptyStringOrUndefined(
              apiCommitment.delivery.address.addressLine2
            ),
            streetPob: nonEmptyStringOrUndefined(
              apiCommitment.delivery.address.streetPob
            ),
            countryCode: nonEmptyStringOrUndefined(
              apiCommitment.delivery.address.countryCode
            ),
            zipCode: nonEmptyStringOrUndefined(
              apiCommitment.delivery.address.zipCode
            ),
            city: nonEmptyStringOrUndefined(
              apiCommitment.delivery.address.city
            ),
            district: nonEmptyStringOrUndefined(
              apiCommitment.delivery.address.district
            ),
            federalState: nonEmptyStringOrUndefined(
              apiCommitment.delivery.address.federalState
            ),
            county: nonEmptyStringOrUndefined(
              apiCommitment.delivery.address.county
            ),
            email: nonEmptyStringOrUndefined(
              apiCommitment.delivery.address.email
            ),
            phone1: nonEmptyStringOrUndefined(
              apiCommitment.delivery.address.phone1
            ),
            phone2: nonEmptyStringOrUndefined(
              apiCommitment.delivery.address.phone2
            ),
            mobile1: nonEmptyStringOrUndefined(
              apiCommitment.delivery.address.mobile1
            ),
            mobile2: nonEmptyStringOrUndefined(
              apiCommitment.delivery.address.mobile2
            )
          }
        }
      : undefined
  };
}

function nonEmptyStringOrUndefined(value: string): string | undefined {
  if (!_.isUndefined(value) && !_.isNull(value) && value !== '') {
    return value;
  }

  return undefined;
}

export function parseCommitmentItem(
  apiItem: ICommitmentItemApi
): ICommitmentItem {
  return {
    ...apiItem,
    relatedProducts: apiItem.relatedProducts?.map(parseCommitmentItem) ?? []
  };
}
